import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import config from 'config'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'

import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'
import firebase from 'firebase/app'
import 'firebase/database'
if (firebase.apps.length === 0) {
  firebase.initializeApp(config.firebase)
}

const middleware = []
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Route path='/' component={App} />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)
