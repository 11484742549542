import styled from 'styled-components'
export default styled.button`
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  margin-top: 40px;
  display: block;
  width: 100%;
  border-radius: 20px;
  text-align: center;

  transition: opacity 0.3s ease-in-out;
  &:disabled {
    opacity: 0.4;
  }

  ${({ theme, invert, error }) => invert ? `
    color: ${error ? theme.colors.red : theme.colors.green};
    background-color: white;
    border: 1px solid ${error ? theme.colors.red : theme.colors.green};
  ` : `
    background-color: ${theme.colors.green};
    -webkit-box-shadow: 3px 5px 10px 0px rgba(93,174,58,0.6);
    -moz-box-shadow: 3px 5px 10px 0px rgba(93,174,58,0.6);
    box-shadow: 3px 5px 10px 0px rgba(93,174,58,0.6);
  `}

  .spinner > svg {
    display: block;
    height: 20px;
    margin: 10px auto;
  }

  > svg {
    display: inline-block;
    vertical-align: top;
    height: 20px;
    margin-top: 10px;
    margin-right: 10px;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  ${({ isLoading }) => isLoading ? `
    cursor: default;
    opacity: 0.7;
    .spinner {
      display: block;
    }
    > svg, span { display: none; }
  ` : `
    .spinner { display: none; }
    > svg, span { display: inline-block; vertical-align: top; }
  `}
`
