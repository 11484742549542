module.exports = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING ? {
      apiHost: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api',
      paymentURL: 'https://demo2.2c2p.com/2C2PFrontEnd/RedirectV3/payment',
      resultURL1: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api/webhooks/2c2p-web-redirect',
      firebase: {
        apiKey: 'AIzaSyA-_nu2M77p5ds2yhcqVxuahaxE8K238YM',
        authDomain: 'o-plus-staging.firebaseapp.com',
        databaseURL: 'https://o-plus-staging.firebaseio.com',
        projectId: 'o-plus-staging',
        storageBucket: 'o-plus-staging.appspot.com',
        messagingSenderId: '830059513859',
        appId: '1:830059513859:web:abdd28f9059a6e49cf2636',
        measurementId: 'G-K5Z54F3WZE'
      }
    } : {
      apiHost: 'https://asia-east2-o-plus-22e7f.cloudfunctions.net/api',
      paymentURL: 'https://t.2c2p.com/RedirectV3/payment',
      resultURL1: 'https://asia-east2-o-plus-22e7f.cloudfunctions.net/api/webhooks/2c2p-web-redirect',
      firebase: {
        apiKey: 'AIzaSyD33DGoVECEKOUKXpK7gclxblFphfumteU',
        authDomain: 'o-plus-22e7f.firebaseapp.com',
        databaseURL: 'https://o-plus-22e7f.firebaseio.com',
        projectId: 'o-plus-22e7f',
        storageBucket: 'o-plus-22e7f.appspot.com',
        messagingSenderId: '366954661806',
        appId: '1:366954661806:web:c0af80fcc5fd36a9e3ea3f',
        measurementId: 'G-6HW2KYH8YX'
      }
    }
  }

  // Default config
  return {
    apiHost: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api',
    paymentURL: 'https://demo2.2c2p.com/2C2PFrontEnd/RedirectV3/payment',
    resultURL1: 'https://asia-east2-o-plus-staging.cloudfunctions.net/api/webhooks/2c2p-web-redirect',
    firebase: {
      apiKey: "AIzaSyA-_nu2M77p5ds2yhcqVxuahaxE8K238YM",
      authDomain: "o-plus-staging.firebaseapp.com",
      databaseURL: "https://o-plus-staging.firebaseio.com",
      projectId: "o-plus-staging",
      storageBucket: "o-plus-staging.appspot.com",
      messagingSenderId: "830059513859",
      appId: "1:830059513859:web:abdd28f9059a6e49cf2636",
      measurementId: "G-K5Z54F3WZE",
    },
  };
})();
