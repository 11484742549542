import { useState, useEffect } from "react";
import { callAPI } from "helpers";
import { get, find } from "lodash";
import { useSelector } from "react-redux";

// Firebase
import firebase from "firebase/app";
import "firebase/database";

const usePaymentLiveFeeds = (classCode) => {
  const [liveFeedClassesLoading, setLiveFeedClassesLoading] = useState(false);
  const [liveFeedClasses, setLiveFeedClasses] = useState([]);
  const [fullClassMap, setFullClassMap] = useState({});
  const _student = useSelector(
    (state) => get(state, "app.currentStudent") || {}
  );

  const activeClass = find(
    liveFeedClasses,
    (_class) => _class.classCode === classCode
  );
  const bookingKey = activeClass
    ? `${activeClass.classCode}-${activeClass.semesterCode.replace("/", "_")}`
    : null;

  useEffect(() => {
    const fetchLiveFeedClasses = async () => {
      try {
        setLiveFeedClassesLoading(true);
        const { result: _classes } = await callAPI({
          url: "/live-feed-classes",
        });
        setLiveFeedClasses(_classes);
        setLiveFeedClassesLoading(false);
      } catch (error) {
        console.error("Error while trying to fetch live feed classes.", error);
        setLiveFeedClassesLoading(false);
      }
    };

    fetchLiveFeedClasses();
  }, []);

  useEffect(() => {
    const bookingRef = firebase.database().ref("bookings").child("isFull");
    bookingRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        setFullClassMap(snapshot.val());
      }
    });
    return () => bookingRef.off("value");
  }, []);

  const [hasPendingOrder, setHasPendingOrder] = useState(false);
  useEffect(() => {
    const checkPendingOrder = () => {
      firebase
        .database()
        .ref("bookings")
        .child("classes")
        .child(bookingKey)
        .child("queue")
        .child("pending")
        .child(_student.id)
        .once("value", (snapshot) => {
          setHasPendingOrder(snapshot.exists());
        });
    };

    if (bookingKey && _student.id) {
      checkPendingOrder();
    }
  }, [bookingKey, _student.id]);

  return {
    liveFeedClassesLoading,
    liveFeedClasses,
    classIsFull: Boolean(fullClassMap[bookingKey]) && !hasPendingOrder,
  };
};

export default usePaymentLiveFeeds;
