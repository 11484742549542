const INITIAL_STATE = {
  schools: [],
  majors: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AVAILABLE_CLASSES_UPDATED':
      return { ...state, availableClasses: action.classes }
    case 'MAJORS_UPDATED':
      return { ...state, majors: action.majors }
    case 'SCHOOLS_UPDATED':
      return { ...state, schools: action.schools }
    case 'VERIFYING':
      return { ...state, verifying: true, currentStudent: null }
    case 'VERIFIED':
      return { ...state, verifying: false, currentStudent: action.currentStudent }
    case 'SAVE_STUDENT_SUCCESS':
      return { ...state, currentStudent: action.currentStudent }
    default:
      return state
  }
}
