import styled from 'styled-components'
import { getTheme } from 'helpers'

export default styled.input`
  display: block;
  height: 40px;
  border: 1px solid ${getTheme`colors.gray`};
  outline: none;
  text-indent: 40px;
  padding: 0px;
  width: calc(100% - 2px);
  border-radius: 5px;
  font-size: 14px;
  &:disabled {
    background-color: #e6e6e6;
  }
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: ${getTheme`colors.gray`};
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: ${getTheme`colors.gray`};
  opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: ${getTheme`colors.gray`};
  opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${getTheme`colors.gray`};
  }
  &:focus {
    border-color: ${getTheme`colors.secondary`};
  }
`
