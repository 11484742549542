import { useState, useEffect } from 'react'
import { callAPI } from 'helpers'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

const useAvailableClasses = () => {
  const dispatch = useDispatch()
  const _storedClasses = useSelector(state => get(state, 'app.availableClasses')) || []
  const [loading, setLoading] = useState(false)
  const [classes, setClasses] = useState(_storedClasses)

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        setLoading(true)
        const { result: _classes } = await callAPI({ url: '/available-classes' })
        setClasses(_classes)
        setLoading(false)
        dispatch({ type: 'AVAILABLE_CLASSES_UPDATED', classes: _classes })
      } catch (error) {
        console.error('Error while trying to fetch classes.', error)
        setLoading(false)
      }
    }

    if (!_storedClasses.length) { fetchClasses() }
  }, [dispatch, _storedClasses.length])

  return {
    classesLoading: loading,
    classes
  }
}

export default useAvailableClasses
