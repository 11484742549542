import styled from 'styled-components'
export default styled.div`
  position: relative;
  margin-bottom: 10px;
  &:last-child { margin-bottom: 0px; }
  ${({ error, theme }) => error ? `
    input, textarea, select, .container {
      background-color: #ffeaea;
      color: #ed1c24;
      border-color: #ed1c24 !important;
    }
    .icon-wrapper > svg > path { fill: #ed1c24; }
    .error-text {
      display: block;
      position: absolute;
      text-align: left;
      cursor: text;
      z-index: 2;
      top: 2px;
      left: 40px;
      right: 40px;
      bottom: 2px;
      line-height: 38px;
      background-color: #ffeaea;
      color: #ed1c24;
      font-size: 14px;
    }
  ` : `
    input, textarea, select {
      background-color: white;
    }
    .error-text { display: none; }
  `}
`
