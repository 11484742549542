import React, { useState, useRef } from "react";
import { getTheme, callAPI } from "helpers";
import Cookies from "js-cookie";

// Components
import { Link } from "react-router-dom";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import Spinner from "components/Spinner";
import ContentBox from "components/ContentBox";
import FormGroup from "components/FormGroup";
import FormInput from "components/FormInput";
import FormButton from "components/FormButton";
import IconWrapper from "components/IconWrapper";

const Login = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();

  const [focusKey, setFocusKey] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const _onLogin = async () => {
    if (submitting) return;
    const _errors = {
      username: !username && "กรุณาระบุ User Name",
      password: !password && "กรุณาระบุรหัสผ่าน",
      login: null,
    };
    setErrors(_errors);
    if (!Object.keys(_errors).some((key) => _errors[key])) {
      try {
        setSubmitting(true);
        // Replace accessToken (OPLUS-API) to token (OPLUS-CLOUD_API)
        const {
          result: { token },
        } = await callAPI({
          method: "POST",
          url: "/students/login",
          body: {
            username,
            password,
          },
        });
        setSubmitting(false);
        Cookies.set("_optkn", token, { expires: 365 });
        window.location.href = "/payment";
      } catch (error) {
        console.error("Error while trying to submit a login form.", error);
        setSubmitting(false);
        setErrors({
          login: "User Name หรือรหัสผ่านไม่ถูกต้อง",
        });
      }
    }
  };

  const _onErrorClear = (ref, key) => {
    ref.current.focus();
    setErrors((_errors) => ({ ..._errors, [key]: null }));
  };

  const _onKeyPress = (e) => {
    if (e.key === "Enter") {
      _onLogin();
    }
  };

  return (
    <ContentBox>
      <FormHead>Sign In</FormHead>
      <FormHeadUnderline />
      <FormBody>
        <FormDescription>
          <p>หากท่านเคยลงทะเบียนแล้ว</p>
          <p>
            ท่านสามารถเข้าสู่ระบบด้วยแอคเคาท์ <span>ที่เคยลงทะเบียนไว้</span>
          </p>
        </FormDescription>
        <div>
          <FormGroup error={errors.username}>
            <FormInput
              ref={usernameRef}
              placeholder="User Name"
              onFocus={() => setFocusKey("username")}
              onBlur={() => setFocusKey()}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={_onKeyPress}
            />
            <IconWrapper
              className="icon-wrapper"
              isFocused={focusKey === "username"}
            >
              <SVG src="/svg/icon-user.svg" />
            </IconWrapper>
            <div
              className="error-text"
              onClick={() => _onErrorClear(usernameRef, "username")}
            >
              {errors.username}
            </div>
          </FormGroup>
          <FormGroup error={errors.password}>
            <FormInput
              type="password"
              ref={passwordRef}
              placeholder="***************"
              onFocus={() => setFocusKey("password")}
              onBlur={() => setFocusKey()}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={_onKeyPress}
            />
            <IconWrapper
              className="icon-wrapper"
              isFocused={focusKey === "password"}
            >
              <SVG src="/svg/icon-key.svg" />
            </IconWrapper>
            <div
              className="error-text"
              onClick={() => _onErrorClear(passwordRef, "password")}
            >
              {errors.password}
            </div>
          </FormGroup>
        </div>
        <FormButton isLoading={submitting} onClick={_onLogin}>
          <Spinner />
          <SVG src="/svg/icon-login.svg" />
          <span>เข้าสู่ระบบ</span>
        </FormButton>
        {errors.login && <LoginError>{errors.login}</LoginError>}
        <ForgotPasswordWrapper>
          <Link to="/forgot-password">ลืมรหัสผ่าน</Link>
        </ForgotPasswordWrapper>
      </FormBody>
    </ContentBox>
  );
};

export default Login;

const FormHead = styled.div`
  color: ${getTheme`colors.primary`};
  font-weight: 700;
  font-style: italic;
  @media (min-width: 426px) {
    font-size: 48px;
  }
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;
const FormHeadUnderline = styled.div`
  margin: 0 auto;
  background-color: ${getTheme`colors.blue`};

  @media (min-width: 426px) {
    width: 80px;
    margin-top: 5px;
    margin-bottom: 30px;
    height: 6px;
    border-radius: 3px;
  }
  @media (max-width: 425px) {
    width: 60px;
    margin-top: 5px;
    margin-bottom: 20px;
    height: 4px;
    border-radius: 2px;
  }
`;
const FormBody = styled.div`
  margin: 0 auto;
  @media (min-width: 426px) {
    width: 320px;
  }
  @media (max-width: 425px) {
    width: 240px;
  }
`;
const FormDescription = styled.div`
  p {
    margin: 0;
    padding: 0;
    line-height: 20px;
  }

  @media (min-width: 426px) {
    margin-bottom: 40px;
    p {
      font-size: 14px;
    }
  }
  @media (max-width: 425px) {
    margin-bottom: 20px;
    p {
      font-size: 12px;
      span {
        display: block;
      }
    }
  }
`;
const LoginError = styled.div`
  color: #ed1c24;
  margin-top: 20px;
  &:before {
    content: "*";
  }
  @media (min-width: 426px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;
const ForgotPasswordWrapper = styled.div`
  margin-top: 20px;
  a {
    color: ${getTheme`colors.primary`};
  }
`;
