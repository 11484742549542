import React, { useState, useRef } from 'react'
import { getTheme, emailIsValid, callAPI } from 'helpers'
import { useHistory } from 'react-router-dom'

// Components
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import ContentBox from 'components/ContentBox'
import FormGroup from 'components/FormGroup'
import FormInput from 'components/FormInput'
import IconWrapper from 'components/IconWrapper'
import FormButton from 'components/FormButton'
import Spinner from 'components/Spinner'

const ForgotPassword = () => {
  const history = useHistory()
  const emailRef = useRef()
  const [email, setEmail] = useState('')
  const [focusKey, setFocusKey] = useState(false)

  const [submitting, setSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [errors, setErrors] = useState({})
  const _onSubmit = async () => {
    if (submitting) return
    let _errors = {
      email: !email
        ? 'กรุณาระบุอีเมล'
        : !emailIsValid(email) ? 'รูปแบบอีเมลไม่ถูกต้อง' : null
    }
    setErrors(_errors)
    if (!Object.keys(_errors).some(key => _errors[key])) {
      try {
        setSubmitting(true)
        setSubmitSuccess(false)
        await callAPI({
          method: 'POST',
          url: '/students/forgot-password',
          body: { email }
        })
        setSubmitting(false)
        setEmail('')
        setSubmitSuccess(true)
        setTimeout(() => setSubmitSuccess(false), 1000)
      } catch (error) {
        console.error('Error while trying to submit a forgot password form.', error)
        setSubmitting(false)
        setSubmitSuccess(false)
      }
    }
  }

  const _onErrorClear = (ref, key) => {
    if (ref.current) { ref.current.focus() }
    setErrors(_errors => ({ ..._errors, [key]: null }))
  }

  return (
    <CustomContentBox>
      <FormHead>ลืมรหัสผ่าน</FormHead>
      <FormHeadUnderline />
      <FormBody>
        <FormDescription>
          <p>กรุณาระบุอีเมลที่ท่านใช้ลงทะเบียน</p>
          <p>ระบบจะแจ้งรหัสผ่านไปทางอีเมล</p>
        </FormDescription>
        <FormGroup error={errors.email}>
          <FormInput
            ref={emailRef}
            placeholder='อีเมล'
            onFocus={() => setFocusKey('email')}
            onBlur={() => setFocusKey()}
            value={email}
            onChange={e => setEmail(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'email'}>
            <SVG src='/svg/icon-email.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(emailRef, 'email')}>
            {errors.email}
          </div>
        </FormGroup>
        <FormButton
          style={{
            opacity: submitSuccess ? 0.7 : 1,
            cursor: submitSuccess ? 'default' : 'pointer'
          }}
          isLoading={submitting}
          onClick={_onSubmit}>
          <Spinner />
          {(submitSuccess && !errors.forgotPassword) && <SVG src='/svg/icon-check.svg' />}
          <span>{(submitSuccess && !errors.changePassword) ? 'ส่งอีเมลสำเร็จ' : 'ยืนยันอีเมล'}</span>
        </FormButton>
        <FormButton style={{ marginTop: 20 }} invert onClick={() => history.replace('/login')}>ย้อนกลับ</FormButton>
      </FormBody>
    </CustomContentBox>
  )
}

export default ForgotPassword

const CustomContentBox = styled(ContentBox)`
  @media(max-width: 425px) {
    margin-top: 60px;
  }
`

const FormHead = styled.div`
  color: ${getTheme`colors.primary`};
  font-weight: 700;
  font-style: italic;
  @media(min-width: 426px) {
    font-size: 36px;
  }
  @media(max-width: 425px) {
    font-size: 24px;
  }
`
const FormHeadUnderline = styled.div`
  margin: 0 auto;
  background-color: ${getTheme`colors.blue`};

  @media(min-width: 426px) {
    width: 80px;
    margin-top: 5px;
    margin-bottom: 30px;
    height: 6px;
    border-radius: 3px;
  }
  @media(max-width: 425px) {
    width: 60px;
    margin-top: 5px;
    margin-bottom: 20px;
    height: 4px;
    border-radius: 2px;
  }
`
const FormDescription = styled.div`
  p {
    margin: 0;
    padding: 0;
    line-height: 20px;
  }

  @media(min-width: 426px) {
    margin-bottom: 40px;
    p {
      font-size: 14px;
    }
  }
  @media(max-width: 425px) {
    margin-bottom: 20px;
    p {
      font-size: 12px;
      span { display: block; }
    }
  }
`
const FormBody = styled.div`
  margin: 0 auto;
  @media(min-width: 426px) {
    width: 320px;
  }
  @media(max-width: 425px) {
    width: 240px;
  }
`
