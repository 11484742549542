import { getTheme } from 'helpers'
import styled from 'styled-components'
export default styled.select`
  display: block;
  height: 42px;
  padding: 1px;
  background-color: ${({ error, theme }) => error ? '#ffeaea !important' : 'white'};
  border: 1px solid ${getTheme`colors.gray`};
  outline: none;
  text-indent: 40px;
  width: 100%;
  color: ${({ error, value, theme }) => error ? '#ed1c24' : value ? 'black' : theme.colors.gray};
  border-radius: 5px;
  font-size: 14px;
  margin: 0px;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';

  &:before {
    content: 'กรุณากรอก' 
  }
  &:focus {
    border-color: ${getTheme`colors.secondary`};
  }
  &:disabled {
    background-color: ${getTheme`colors.border`};
  }
  option:first-child { display: none; }
`
