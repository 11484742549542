import styled from 'styled-components'

export default styled.div`
  background-color: white;
  margin: 0 auto;
  border-radius: 4px;

  @media(min-width: 426px) {
    padding: 20px 20px 40px;
    width: 385px;
    -webkit-box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.05);
  }
  @media(max-width: 425px) {
    padding: 20px 0px 40px;
    width: calc(100vw - 30px);
    margin: 0px 15px;
  }
`
