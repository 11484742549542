import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { getTheme, callAPI } from 'helpers'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import SVG from 'react-inlinesvg'

// Components
import Spinner from 'components/Spinner'
import { slide as SideBar } from 'react-burger-menu'

export default () => {
  const location = useLocation()
  const _verifying = useSelector(state => get(state, 'app.verifying'))
  const _currentStudent = useSelector(state => get(state, 'app.currentStudent'))

  const [sideBarVisible, setSideBarVisible] = useState(false)
  const sideBarProps = {
    isOpen: sideBarVisible,
    styles: {
      bmMenuWrap: {
        width: 240,
        backgroundColor: 'white'
      },
      bmMenu: {
        padding: 0
      },
      bmBurgerButton: {
        display: 'none'
      }
    }
  }

  const [loggingOut, setLoggingOut] = useState(false)
  const _onLogout = async () => {
    if (loggingOut) return
    try {
      setLoggingOut(true)
      await callAPI({ method: 'POST', url: '/students/logout' })
      Cookies.remove('_optkn')
      setLoggingOut(false)
      window.location.href = '/login'
    } catch (error) {
      console.error('Error while trying to log out.', error)
      Cookies.remove('_optkn')
      setLoggingOut(false)
    }
  }

  const authenticated = Boolean(get(_currentStudent, 'studentId'))
  return (
    <NavBar authenticated={authenticated}>
      {authenticated && (
        <BtnBurger onClick={() => setSideBarVisible(visible => !visible)}>
          <SVG src='/svg/icon-bars.svg' />
        </BtnBurger>
      )}
      <GridContainer>
        <LogoWrapper authenticated={Boolean(get(_currentStudent, 'studentId'))} target='_blank' href='https://www.oplus-school.com'>
          <Logo src='/images/logo.png' />
        </LogoWrapper>
        {_verifying ? null : get(_currentStudent, 'studentId') ? (
          <Menus authenticated={authenticated}>
            <Desktop>
              <MenuButton className={location.pathname.indexOf('payment') > -1 && 'active'}>
                <Link to='/payment' style={{ lineHeight: '28px' }}>
                  <span>สมัคร</span>
                  <strong>เรียน</strong>
                </Link>
              </MenuButton>
              <MenuButton className={location.pathname.indexOf('change-password') > -1 && 'active'}>
                <Link to='/change-password' style={{ lineHeight: '28px' }}>
                  <span>เปลี่ยน</span>
                  <strong>รหัสผ่าน</strong>
                </Link>
              </MenuButton>
              <MenuButton className={location.pathname.indexOf('edit-info') > -1 && 'active'}>
                <Link to='/edit-info' style={{ lineHeight: '28px' }}>
                  <span>แก้ไข</span>
                  <strong>ข้อมูลส่วนตัว</strong>
                </Link>
              </MenuButton>
              <BtnLogout onClick={_onLogout}>
                {loggingOut ? <Spinner /> : <SVG src='/svg/icon-power.svg' />}
                <span>ออกจากระบบ</span>
              </BtnLogout>
            </Desktop>
          </Menus>
        ) : (location.pathname.indexOf('signup') > -1 || location.pathname.indexOf('login') > -1) && (
          <Menus>
            <MenuButton className={location.pathname.indexOf('signup') > -1 && 'active'}>
              <Link to='/signup'>
                <span>สำหรับ</span>
                <strong>นักเรียนใหม่</strong>
              </Link>
            </MenuButton>
            <MenuButton className={location.pathname.indexOf('login') > -1 && 'active'}>
              <Link to='/login'>
                <span>สำหรับ</span>
                <strong>นักเรียนเก่า</strong>
              </Link>
            </MenuButton>
          </Menus>
        )}
      </GridContainer>
      {authenticated && (
        <Mobile>
          <SideBar {...sideBarProps}>
            <SideBarButton
              onClick={() => setSideBarVisible(false)}
              className={location.pathname.indexOf('payment') > -1 && 'active'}>
              <Link to='/payment'>
                <span>สมัคร</span>
                <strong>เรียน</strong>
              </Link>
            </SideBarButton>
            <SideBarButton
              onClick={() => setSideBarVisible(false)}
              className={location.pathname.indexOf('change-password') > -1 && 'active'}>
              <Link to='/change-password'>
                <span>เปลี่ยน</span>
                <strong>รหัสผ่าน</strong>
              </Link>
            </SideBarButton>
            <SideBarButton
              onClick={() => setSideBarVisible(false)}
              className={location.pathname.indexOf('edit-info') > -1 && 'active'}>
              <Link to='/edit-info'>
                <span>แก้ไข</span>
                <strong>ข้อมูลส่วนตัว</strong>
              </Link>
            </SideBarButton>
            <SideBarButton onClick={_onLogout}>
              {loggingOut ? <Spinner /> : null}
              <span>ออกจากระบบ</span>
            </SideBarButton>
          </SideBar>
        </Mobile>
      )}
    </NavBar>
  )
}

const NavBar = styled.div`
  @media(min-width: 426px) {
    background-color: white;
    line-height: 62px;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
  }
  @media(max-width: 425px) {
    background-color: white;
    line-height: 62px;
    ${({ authenticated }) => authenticated ? `
      text-align: left;
      img { margin-left: 10px; }
    ` : `
      text-align: center;
      margin-bottom: 75px;
    `};
  }
`
const LogoWrapper = styled.a`
  ${({ authenticated }) => authenticated && `
    @media(max-width: 767px) {
      display: block;
      width: 160px;
      margin: 0 auto;
    }
  `}
`
const Logo = styled.img`
  display: inline-block;
  vertical-align: top;
  height: 40px;
  margin-top: 10px;
`
const GridContainer = styled.div`
  position: relative;
  @media(min-width: 1200px) {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
  }
  @media(min-width: 426px) and (max-width: 1199px) {
    width: calc(100vw - 30px);
    margin: 0px 15px;
  }
`
const Menus = styled.div`
  @media(min-width: 426px) {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  @media(max-width: 425px) {
    position: absolute;
    ${({ authenticated }) => authenticated ? `
      top: 0px;
      right: 10px;
    ` : `
      top: 70px;
      left: 0;
      right: 0;
    `}
  }
`
const MenuButton = styled.div`
  display: inline-block;
  vertical-align: top;
  @media(min-width: 426px) {
    width: 120px;
  }
  @media(max-width: 425px) {
    width: 40vw;
  }
  text-align: center;
  line-height: 40px;
  margin-top: 10px;
  a {
    padding: 6px 0px;
    display: block;
    color: ${getTheme`colors.primary`};
    text-decoration: none;
  }

  border-radius: 20px;
  &:last-child {
    margin-left: 10px;
  }

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: ${getTheme`colors.blue`};
  }

  span, strong {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }

  &.active {
    a {
      color: white;
    }
    cursor: default;
    background-color: ${getTheme`colors.secondary`};
    -webkit-box-shadow: 3px 5px 10px 0px rgba(0,161,214,0.5);
    -moz-box-shadow: 3px 5px 10px 0px rgba(0,161,214,0.5);
    box-shadow: 3px 5px 10px 0px rgba(0,161,214,0.5);
    strong { font-weight: 700;}
  }
`

const BtnLogout = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 40px;
  padding: 0px 10px;
  height: 38px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid ${getTheme`colors.secondary`};
  background-color: white;
  color: ${getTheme`colors.secondary`};
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  &:hover, &.loading {
    color: white;
    border-color: ${getTheme`colors.primary`};
    background-color: ${getTheme`colors.primary`};
  }
  &.loading { cursor: default; }

  .spinner {
    display: inline-block;
    vertical-align: top;
  }
  .spinner > svg, svg {
    height: 20px;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 10px;
  }
  @media(max-width: 425px) {
    padding: 0px 10px;
    font-size: 14px;
    height: 28px;
    margin-top: 15px;
    line-height: 30px;
    .spinner > svg, svg {
      height: 14px;
      margin-top: 8px;
      margin-right: 8px;
    }
  }
`
const Desktop = styled.div`
  @media(max-width: 767px) { display: none; }
`
const Mobile = styled.div`
  @media(min-width: 768px) { display: none; }
`
const BtnBurger = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: ${getTheme`colors.primary`};
  z-index: 10000;
  > svg {
    vertical-align: top;
    margin-top: 20px;
    height: 20px;
  }
  @media(min-width: 768px) { display: none; }
`
const SideBarButton = styled.div`
  height: 60px;
  outline: none;
  padding-left: 20px;
  cursor: pointer;
  color: ${getTheme`colors.primary`};
  
  > a {
    display: block;
    line-height: 60px;
    color: ${getTheme`colors.primary`};
    text-decoration: none;
    span, strong { font-weight: 400; }
  }
  &.active {
    a { color: white; }
    cursor: default;
    background-color: ${getTheme`colors.secondary`};
    strong { font-weight: 700;}
  }
  .spinner {
    display: inline-block;
    vertical-align: top;
  }
  .spinner > svg, svg {
    height: 14px;
    margin-top: 8px;
    margin-right: 8px;
  }
`
