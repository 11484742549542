import { getTheme } from 'helpers'
import styled from 'styled-components'
export default styled.textarea`
  display: block;
  line-height: 20px;
  height: 90px;
  background-color: ${({ error, theme }) => error ? '#ffeaea' : 'white'};
  border: 1px solid ${getTheme`colors.gray`};
  outline: none;
  padding: 0px;
  padding-top: 10px;
  padding-left: 40px;
  resize: none;
  width: calc(100% - 42px);
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: ${getTheme`colors.gray`};
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: ${getTheme`colors.gray`};
  opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: ${getTheme`colors.gray`};
  opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${getTheme`colors.gray`};
  }
  &:focus {
    border-color: ${getTheme`colors.secondary`};
  }
  &:disabled {
    background-color: ${getTheme`colors.background`};
  }
`
