import React, { useState, useRef, useEffect } from 'react'
import { callAPI, getTheme, emailIsValid, identificationIdIsValid, uploadToS3 } from 'helpers'
import { get, find } from 'lodash'
import { saveAs } from 'file-saver'
import Cookies from 'js-cookie'

// Components
import theme from 'theme'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import Spinner from 'components/Spinner'
import ContentBox from 'components/ContentBox'
import FormGroup from 'components/FormGroup'
import IconWrapper from 'components/IconWrapper'
import FormInput from 'components/FormInput'
import FormTextarea from 'components/FormTextarea'
import FormSelect from 'components/FormSelect'
import FormButton from 'components/FormButton'
import Autocomplete from 'react-autocomplete'

// Hooks
import useSchools from 'hooks/useSchools'
import useMajors from 'hooks/useMajors'
import usePostalCodes from 'hooks/usePostalCodes'

const SCHOOL_PLACEHOLDER = 'โรงเรียนที่กำลังศึกษาอยู่'
const MAJOR_PLACEHOLDER = 'ระดับชั้น'
const POSTAL_CODE_PLACEHOLDER = 'รหัสไปรษณีย์'
const SALUTATION_PLACEHOLDER = 'คำนำหน้าชื่อ'

const salutations = [
  { value: 'ดช', label: 'ด.ช.', gender: 'M' },
  { value: 'ดญ', label: 'ด.ญ.', gender: 'F' },
  { value: 'นาย', label: 'นาย', gender: 'M' },
  { value: 'นางสาว', label: 'นางสาว', gender: 'F' }
]

const Signup = () => {
  const { schoolsLoading, schools } = useSchools()
  const { majorsLoading, majors } = useMajors()
  const { postalCodes } = usePostalCodes()

  const salutationRef = useRef()
  const fullNameRef = useRef()
  const emailRef = useRef()
  const identificationIdRef = useRef()
  const phoneRef = useRef()
  const addressRef = useRef()
  const postalCodeRef = useRef()
  const schoolIdRef = useRef()
  const majorIdRef = useRef()
  const imageRef = useRef()

  const [focusKey, setFocusKey] = useState()
  const [salutation, setSalutation] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [identificationId, setIdentificationId] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [schoolName, setSchoolName] = useState('')
  const [majorId, setMajorId] = useState()
  const [displayFile, setDisplayFile] = useState()
  const [displayURL, setDisplayURL] = useState('')

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const _onSubmit = async () => {
    if (submitting) return
    const schoolId = get(find(schools, _school => _school.schoolName === schoolName), 'schoolId')
    const _errors = {
      salutation: !salutation && 'กรุณาระบุคำนำหน้าชื่อ',
      fullName: !fullName && 'กรุณาระบุชื่อ-สกุล',
      address: !address && 'กรุณาระบุที่อยู่',
      postalCode: !postalCode && 'กรุณาระบุรหัสไปรษณีย์',
      schoolId: !schoolId && 'กรุณาระบุโรงเรียนที่กำลังศึกษาอยู่',
      majorId: !majorId && 'กรุณาระบุระดับชั้น',
      phone: !phone && 'กรุณาระบุเบอร์โทรศัพท์',
      identificationId: !identificationId
        ? 'กรุณาระบุเลขบัตรประชาชน'
        : !identificationIdIsValid(identificationId) ? 'เลขบัตรประชาชนไม่ถูกต้อง' : null,
      email: !email
        ? 'กรุณาระบุอีเมล'
        : !emailIsValid(email) ? 'รูปแบบอีเมลไม่ถูกต้อง' : null,
      displayImage: !displayFile && 'กรุณาอัพโหลดรูปนักเรียน'
    }
    setErrors(_errors)
    setSubmitting(true)
    if (!Object.keys(_errors).some(key => _errors[key])) {
      try {
      // Upload display image to S3
        const { url } = await callAPI({
          method: 'POST',
          url: '/s3/signed-url',
          body: {
            key: `students/${new Date().getTime().toString(16)}.${displayFile.type.replace('image/', '')}`,
            contentType: displayFile.type
          }
        })
        const picturePath = await uploadToS3({ url, file: displayFile })
        const [firstName, ...rest] = fullName.split(' ')
        const { result } = await callAPI({
          method: 'POST',
          url: '/students/register',
          body: {
            gender: find(salutations, ({ value }) => value === salutation).gender,
            salutation,
            firstName,
            lastName: rest.join(' '),
            email,
            identificationId,
            address,
            postalCode,
            phone,
            schoolId,
            majorId,
            picturePath
          }
        })

        // Replace accessToken (OPLUS-API) to token (OPLUS-CLOUD_API)
        const { result: { token } } = await callAPI({
          method: 'POST',
          url: '/students/login',
          body: {
            username: result.username,
            password: result.password
          }
        })
        Cookies.set('_optkn', token, { expires: 365 })

        setUsername(result.username)
        setPassword(result.password)
        setSubmitting(false)
      } catch (error) {
        console.error('Error while trying to submit a signup form.', error)
        setSubmitting(false)
        setErrors({ signup: error.reason })
      }
    }
  }

  useEffect(() => {
    const saveImage = () => {
      try {
        setTimeout(async () => {
          // Save image.
          const infoCard = document.getElementById('info-card')
          const infoButton = document.getElementById('info-button')
          infoButton.style.opacity = 0
          const canvas = await window.html2canvas(infoCard)
          canvas.toBlob(blob => saveAs(blob, 'login-info.png'))
          infoButton.style.opacity = 1
        }, 100)
      } catch (error) {
        console.error('Error while trying to save login info.', error)
      }
    }

    if (username && password) saveImage()
  }, [username, password])

  const _onErrorClear = (ref, key) => {
    if (ref.current) { ref.current.focus() }
    setErrors(_errors => ({ ..._errors, [key]: null }))
  }

  const _onSave = async () => {
    try {
      window.location.href = '/payment'
    } catch (error) {
      console.log('Error while trying to save a login info.', error)
    }
  }

  const registered = username && password
  if (registered) {
    return (
      <InfoCard id='info-card'>
        <CardHead>ลงทะเบียน O-PLUS สำเร็จ</CardHead>
        <CardBody>
          <InfoRow>
            <RowLabel>Username</RowLabel>
            <RowValue>{username}</RowValue>
          </InfoRow>
          <InfoRow>
            <RowLabel>Password</RowLabel>
            <RowValue>{password}</RowValue>
          </InfoRow>
          <div id='info-footer'>
            <InfoRemarkRed>
              <p>กรุณาจด Username และ Password เอาไว้</p>
              <p>สำหรับการเข้าใช้งานในครั้งต่อไป</p>
            </InfoRemarkRed>
            <FormButton id='info-button' style={{ marginTop: 20 }} onClick={_onSave}>ตกลง</FormButton>
            {false && (
              <InfoRemarkGray>
                <p>ระบบจะบันทึกเก็บเป็นไฟล์ลงใน</p>
                <p>เครื่องของคุณโดยอัตโนมัติ</p>
              </InfoRemarkGray>
            )}
          </div>
        </CardBody>
      </InfoCard>
    )
  }

  const _onImageChosen = (e) => {
    const _file = e.target.files[0]
    const _url = URL.createObjectURL(_file)
    setDisplayFile(_file)
    setDisplayURL(_url)
    setErrors(_errors => ({ ..._errors, displayImage: null }))
  }

  return (
    <div>
      <SignupHead>
        <div>กรอกข้อมูลเพื่อสมัครเรียน</div>
        <div>หากเคยกรอกแล้วหรือเรียนอยู่มีรหัสผ่านแล้ว</div>
        <div>ให้เลือกหัวข้อนักเรียนเก่า</div>
      </SignupHead>
      <SignupBox>
        <MobileSignupHead>
          <div>กรอกข้อมูลเพื่อสมัครเรียน</div>
          <div>หากเคยกรอกแล้วหรือเรียนอยู่มีรหัสผ่านแล้ว</div>
          <div>ให้เลือกหัวข้อนักเรียนเก่า</div>
        </MobileSignupHead>
        <FormGroup error={errors.salutation}>
          <FormSelect
            ref={salutationRef}
            placeholder='ระดับชั้น'
            onClick={() => _onErrorClear(salutationRef, 'salutation')}
            onFocus={() => setFocusKey('salutation')}
            onBlur={() => setFocusKey()}
            value={salutation}
            onChange={e => setSalutation(e.target.value === SALUTATION_PLACEHOLDER ? '' : e.target.value)}>
            <option>{errors.salutation ? 'กรุณาระบุ' : ''}{SALUTATION_PLACEHOLDER}</option>
            {salutations.map(({ label, value }) =>
              <option key={value} value={value}>{label}</option>
            )}
          </FormSelect>
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'salutation'}>
            {majorsLoading ? <Spinner /> : <SVG src='/svg/icon-user.svg' />}
          </IconWrapper>
          {!salutation && (
            <IconWrapper
              style={{ left: 'initial', right: 10 }}
              className='icon-wrapper'
              isFocused={focusKey === 'salutation'}>
              <SVG src='/svg/icon-chevron-down.svg' />
            </IconWrapper>
          )}
        </FormGroup>
        <FormGroup error={errors.fullName}>
          <FormInput
            ref={fullNameRef}
            placeholder='ชื่อ-สกุล'
            onFocus={() => setFocusKey('user')}
            onBlur={() => setFocusKey()}
            value={fullName}
            onChange={e => setFullName(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'user'}>
            <SVG src='/svg/icon-user.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(fullNameRef, 'fullName')}>
            {errors.fullName}
          </div>
        </FormGroup>
        <FormGroup error={errors.address}>
          <FormTextarea
            ref={addressRef}
            placeholder='กรอกที่อยู่ให้ถูกต้องสำหรับจัดส่งหนังสือ'
            onFocus={() => setFocusKey('address')}
            onBlur={() => setFocusKey()}
            value={address}
            onChange={e => setAddress(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'address'}>
            <SVG src='/svg/icon-address.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(addressRef, 'address')}>
            {errors.address}
          </div>
        </FormGroup>
        <FormGroup error={errors.postalCode}>
          <AutocompleteWrapper>
            <Autocomplete
              inputProps={{
                ref: postalCodeRef,
                onFocus: () => setFocusKey('postalCode'),
                onBlur: () => setFocusKey(),
                placeholder: POSTAL_CODE_PLACEHOLDER
              }}
              shouldItemRender={(item, value) => value && item.indexOf(value) === 0}
              getItemValue={(item) => item}
              items={postalCodes}
              renderMenu={(items, value, style) => {
                const visible = value && postalCodes.some(code => code.indexOf(value) === 0)
                return <AutocompleteMenu style={{ style, display: visible ? 'block' : 'none' }} children={items} />
              }}
              renderItem={(item, isHighlighted) =>
                <AutocompleteMenuItem key={item} isHighlighted={isHighlighted}>
                  {item}
                </AutocompleteMenuItem>
              }
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              onSelect={(_postalCode) => setPostalCode(_postalCode)}
            />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'address'}>
              <SVG src='/svg/icon-address.svg' />
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(postalCodeRef, 'postalCode')}>
              {errors.postalCode}
            </div>
          </AutocompleteWrapper>
        </FormGroup>
        <FormGroup error={errors.schoolId}>
          <AutocompleteWrapper>
            <Autocomplete
              inputProps={{
                ref: schoolIdRef,
                onFocus: () => setFocusKey('school'),
                onBlur: () => setFocusKey(),
                disabled: schoolsLoading,
                placeholder: SCHOOL_PLACEHOLDER
              }}
              shouldItemRender={(item, value) => value && item.schoolName.toLowerCase().indexOf(value.toLowerCase()) > -1}
              getItemValue={(item) => item.schoolName}
              items={schools}
              renderMenu={(items, value, style) => {
                const visible = value && schools.some(({ schoolName }) => schoolName.toLowerCase().indexOf(value.toLowerCase()) > -1)
                return <AutocompleteMenu style={{ style, display: visible ? 'block' : 'none' }} children={items} />
              }}
              renderItem={(item, isHighlighted) =>
                <AutocompleteMenuItem key={item.schoolId} isHighlighted={isHighlighted}>
                  {item.schoolName}
                </AutocompleteMenuItem>
              }
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              onSelect={(_schoolName) => setSchoolName(_schoolName)}
            />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'school'}>
              {schoolsLoading ? <Spinner /> : <SVG src='/svg/icon-school.svg' />}
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(schoolIdRef, 'schoolId')}>
              {errors.schoolId}
            </div>
          </AutocompleteWrapper>
        </FormGroup>
        <FormGroup error={errors.majorId}>
          <FormSelect
            ref={majorIdRef}
            placeholder='ระดับชั้น'
            onClick={() => _onErrorClear(majorIdRef, 'majorId')}
            onFocus={() => setFocusKey('grade')}
            onBlur={() => setFocusKey()}
            value={majorId}
            disabled={majorsLoading}
            onChange={e => setMajorId(e.target.value === MAJOR_PLACEHOLDER ? '' : e.target.value)}>
            <option>{errors.majorId ? 'กรุณาระบุ' : ''}{MAJOR_PLACEHOLDER}</option>
            {majors.map(({ majorId, majorName }) =>
              <option key={majorId} value={majorId}>{majorName}</option>
            )}
          </FormSelect>
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'grade'}>
            {majorsLoading ? <Spinner /> : <SVG src='/svg/icon-grade.svg' />}
          </IconWrapper>
          {!majorId && (
            <IconWrapper
              style={{ left: 'initial', right: 10 }}
              className='icon-wrapper'
              isFocused={focusKey === 'grade'}>
              {majorsLoading ? null : <SVG src='/svg/icon-chevron-down.svg' />}
            </IconWrapper>
          )}
        </FormGroup>
        <FormGroup error={errors.phone}>
          <FormInput
            ref={phoneRef}
            placeholder='เบอร์โทรศัพท์'
            onFocus={() => setFocusKey('phone')}
            onBlur={() => setFocusKey()}
            value={phone}
            onChange={e => {
              const _num = e.target.value
              if (_num && isNaN(_num)) return
              if (_num.length > 10) return
              setPhone(_num)
            }} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'phone'}>
            <SVG src='/svg/icon-phone.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(phoneRef, 'phone')}>
            {errors.phone}
          </div>
        </FormGroup>
        <FormGroup error={errors.identificationId}>
          <FormInput
            ref={identificationIdRef}
            placeholder='เลขบัตรประชาชน'
            onFocus={() => setFocusKey('card')}
            onBlur={() => setFocusKey()}
            value={identificationId}
            onChange={e => {
              const _id = e.target.value
              if (_id && isNaN(_id)) return
              if (_id.length > 13) return
              setIdentificationId(_id)
            }} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'card'}>
            <SVG src='/svg/icon-card.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(identificationIdRef, 'identificationId')}>
            {errors.identificationId}
          </div>
        </FormGroup>
        <FormGroup error={errors.email}>
          <FormInput
            ref={emailRef}
            placeholder='อีเมล'
            onFocus={() => setFocusKey('email')}
            onBlur={() => setFocusKey()}
            value={email}
            onChange={e => setEmail(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'email'}>
            <SVG src='/svg/icon-email.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(emailRef, 'email')}>
            {errors.email}
          </div>
        </FormGroup>
        <FormGroup error={errors.displayImage}>
          <ImageUploader className='container' onClick={() => imageRef.current.click()}>
            <div>{errors.displayImage && 'กรุณา'}อัพโหลดรูปนักเรียน</div>
            <button>เลือกรูปภาพ</button>
            <input type='file' style={{ display: 'none' }} accept='image/*' ref={imageRef} onChange={_onImageChosen} />
            {Boolean(displayURL) && <img alt='student-pic' src={displayURL} />}
          </ImageUploader>
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'image'}>
            <SVG src='/svg/icon-image.svg' />
          </IconWrapper>
        </FormGroup>
        <PrivacyRemark>
          <div>เมื่อกดปุ่ม "ยืนยัน" จะถือเป็นการแสดงว่าคุณยอมรับว่า</div>
          <div>คุณได้อ่านและเข้าใจใน <a target='_blank' rel='noopener noreferrer' href='https://www.oplus-school.com/privacy-policy'>นโยบายความเป็นส่วนตัวและระเบียบข้อบังคับ</a> แล้ว</div>
        </PrivacyRemark>
        <FormButton
          style={{ marginTop: 0 }}
          isLoading={submitting}
          onClick={_onSubmit}>
          <Spinner />
          <span>ยืนยัน</span>
        </FormButton>
        {errors.signup && <SignupError>{errors.signup}</SignupError>}
      </SignupBox>
    </div>
  )
}

export default Signup

const SignupHead = styled.h1`
  color: ${getTheme`colors.red`} !important;
  > div:before { content: '*'; }
  @media(max-width: 425px) {
    display: none;
  }
`
const MobileSignupHead = styled.div`
  color: ${getTheme`colors.red`};
  > div:before { content: '*'; }
  text-align: left;
  margin-bottom: 5px;
  font-weight: 700;
  @media(min-width: 426px) {
    display: none;
  }
`

const SignupBox = styled(ContentBox)`
  @media(max-width: 425px) {
    width: calc(100vw - 60px);
    padding: 15px 15px 30px;
    margin: 0 15px;
    margin-bottom: 40px;
  }
`
const AutocompleteWrapper = styled.div`
  > div { display: block !important; }
  input {
    display: block;
    height: 40px;
    border: 1px solid ${getTheme`colors.gray`};
    outline: none;
    text-indent: 40px;
    padding: 0px;
    width: calc(100% - 2px);
    border-radius: 5px;
    font-size: 14px;
    &:disabled {
      background-color: #e6e6e6;
    }
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: ${getTheme`colors.gray`};
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: ${getTheme`colors.gray`};
    opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: ${getTheme`colors.gray`};
    opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${getTheme`colors.gray`};
    }
    &:focus {
      border-color: ${getTheme`colors.secondary`};
    }
  }
`
const AutocompleteMenu = styled.div`
  z-index: 9999;
  margin-left: 20px;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid ${getTheme`colors.secondary`};
  max-height: 200px;
  overflow-y: auto;
`
const AutocompleteMenuItem = styled.div`
  border-bottom: 1px solid ${getTheme`colors.blue`};
  line-height: 40px;
  color: black;
  font-size: 14px;
  text-indent: 20px;
  text-align: left;
  cursor: pointer;
  background-color: ${({ isHighlighted }) => isHighlighted ? theme.colors.blue : 'white'};
`

const InfoCard = styled(ContentBox)`
  padding: 0px !important;
  border-radius: 0px !important;
  overflow: hidden;
  border: 1px solid ${getTheme`colors.primary`};
  text-align: left;
`
const CardHead = styled.div`
  color: ${getTheme`colors.primary`};
  background-color: ${getTheme`colors.blue`};
  line-height: 50px;
  text-indent: 20px;
  font-weight: 700;
`
const CardBody = styled.div`
  padding: 20px;
`
const InfoRow = styled.div`
  background-color: #e6e6e6;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 5px 15px;
`
const RowLabel = styled.div`
  color: ${getTheme`colors.gray`};
  font-size: 12px;
`
const RowValue = styled.div`
  color: ${getTheme`colors.primary`};
  font-size: 18px;
`
const InfoRemarkRed = styled.div`
  text-align: center;
  padding-top: 15px;
  p {
    margin: 0;
    padding: 0;
    color: ${getTheme`colors.red`};
    font-size: 12px;
  }
`
const InfoRemarkGray = styled.div`
  text-align: center;
  padding-top: 15px;
  p {
    margin: 0;
    padding: 0;
    color: ${getTheme`colors.gray`};
    font-size: 10px;
    line-height: 12px;
  }
`
const SignupError = styled.div`
  color: #ed1c24;
  margin-top: 20px;
  &:before {
    content: '*';
  }
  @media(min-width: 426px) {
    font-size: 16px;
  }
  @media(max-width: 425px) {
    font-size: 14px;
  }
`
const ImageUploader = styled.div`
  display: block;
  text-indent: 40px;
  text-align: left;
  border: 1px solid ${getTheme`colors.gray`};
  border-radius: 5px;
  color: ${getTheme`colors.gray`};
  font-size: 14px;
  padding: 10px 0px;

  button {
    margin-left: 40px;
    margin-top: 5px;
    cursor: pointer;
  }

  img {
    display: block;
    object-fit: cover;
    margin: 20px auto;
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
`
const PrivacyRemark = styled.div`
  max-width: 385px;
  margin: 30px auto 15px;

  @media(min-width: 375px) {
    font-size: 14px;
  }
  @media(max-width: 374px) {
    font-size: 12px;
  }

  > div:first-of-type {
    &:before {
      content: '*';
    }
  }

  a {
    color: ${getTheme`colors.secondary`};
  }
`
