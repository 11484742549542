import React, { useState, useRef, useEffect } from 'react'
import { callAPI, getTheme, emailIsValid, identificationIdIsValid, uploadToS3 } from 'helpers'
import { useSelector, useDispatch } from 'react-redux'
import { compact, get, find, omitBy, isNil } from 'lodash'

// Components
import theme from 'theme'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import Spinner from 'components/Spinner'
import ContentBox from 'components/ContentBox'
import FormGroup from 'components/FormGroup'
import IconWrapper from 'components/IconWrapper'
import FormInput from 'components/FormInput'
import FormTextarea from 'components/FormTextarea'
import FormSelect from 'components/FormSelect'
import FormButton from 'components/FormButton'
import Autocomplete from 'react-autocomplete'

// Hooks
import useSchools from 'hooks/useSchools'
import useMajors from 'hooks/useMajors'

const SCHOOL_PLACEHOLDER = 'โรงเรียนที่กำลังศึกษาอยู่'
const MAJOR_PLACEHOLDER = 'ระดับชั้น'

const EditInfo = () => {
  const dispatch = useDispatch()
  const { schoolsLoading, schools } = useSchools()
  const { majorsLoading, majors } = useMajors()
  const _student = useSelector(state => get(state, 'app.currentStudent') || {})

  const fullNameRef = useRef()
  const emailRef = useRef()
  const identificationIdRef = useRef()
  const phoneRef = useRef()
  const addressRef = useRef()
  const schoolIdRef = useRef()
  const majorIdRef = useRef()
  const imageRef = useRef()

  const [focusKey, setFocusKey] = useState()
  const [fullName, setFullName] = useState(compact([_student.firstName, _student.lastName]).join(' '))
  const [email, setEmail] = useState(_student.email)
  const [identificationId, setIdentificationId] = useState(_student.identificationId)
  const [phone, setPhone] = useState(_student.phone)
  const [address, setAddress] = useState(_student.address)
  const [schoolName, setSchoolName] = useState(_student.schoolName)
  const [majorId, setMajorId] = useState(get(find(majors, ({ majorName }) => _student.majorName === majorName), 'majorId'))
  const [displayFile, setDisplayFile] = useState()
  const [displayURL, setDisplayURL] = useState(_student.picturePath)

  const [errors, setErrors] = useState({})

  useEffect(() => setFullName(compact([_student.firstName, _student.lastName]).join(' ')), [_student.firstName, _student.lastName])
  useEffect(() => setEmail(_student.email), [_student.email])
  useEffect(() => setIdentificationId(_student.identificationId), [_student.identificationId])
  useEffect(() => setPhone(_student.phone), [_student.phone])
  useEffect(() => setAddress(_student.address), [_student.address])
  useEffect(() => setSchoolName(_student.schoolName), [_student.schoolName])
  useEffect(() => setDisplayURL(_student.picturePath), [_student.picturePath])
  useEffect(() => {
    setMajorId(get(find(majors, ({ majorName }) => _student.majorName === majorName), 'majorId'))
  }, [majors, _student.majorName, majorsLoading])

  const _onErrorClear = (ref, key) => {
    if (ref.current) { ref.current.focus() }
    setErrors(_errors => ({ ..._errors, [key]: null }))
  }

  const [saving, setSaving] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const _onSave = async () => {
    if (saving) return
    const schoolId = get(find(schools, _school => _school.schoolName === schoolName), 'schoolId')
    const _errors = {
      fullName: !fullName && 'กรุณาระบุชื่อ-สกุล',
      address: !address && 'กรุณาระบุที่อยู่',
      schoolId: !schoolId && 'กรุณาระบุโรงเรียนที่กำลังศึกษาอยู่',
      majorId: !majorId && 'กรุณาระบุระดับชั้น',
      phone: !phone && 'กรุณาระบุเบอร์โทรศัพท์',
      identificationId: !identificationId
        ? 'กรุณาระบุเลขบัตรประชาชน'
        : !identificationIdIsValid(identificationId) ? 'เลขบัตรประชาชนไม่ถูกต้อง' : null,
      email: !email
        ? 'กรุณาระบุอีเมล'
        : !emailIsValid(email) ? 'รูปแบบอีเมลไม่ถูกต้อง' : null,
      displayImage: (!displayFile && !displayURL) && 'กรุณาอัพโหลดรูปนักเรียน'
    }
    setErrors(_errors)
    if (!Object.keys(_errors).some(key => _errors[key])) {
      try {
        setSaving(true)
        let picturePath
        if (displayFile) {
          // Upload display image to S3
          const { url } = await callAPI({
            method: 'POST',
            url: '/s3/signed-url',
            body: {
              key: `students/${new Date().getTime().toString(16)}.${displayFile.type.replace('image/', '')}`,
              contentType: displayFile.type
            }
          })
          picturePath = await uploadToS3({ url, file: displayFile })
        }

        const { result: currentStudent } = await callAPI({
          method: 'PUT',
          url: '/students/me',
          body: omitBy({
            firstName: firstName === _student.firstName ? undefined : firstName,
            lastName: lastName === _student.lastName ? undefined : lastName,
            email: email === _student.email ? undefined : email,
            identificationId: identificationId === _student.identificationId ? undefined : identificationId,
            address: address === _student.address ? undefined : address,
            phone: phone === _student.phone ? undefined : phone,
            schoolId: schoolName === _student.schoolName ? undefined : schoolId,
            majorId: majorId === get(find(majors, ({ majorName }) => _student.majorName === majorName), 'majorId') ? undefined : majorId,
            picturePath
          }, isNil)
        })

        dispatch({ type: 'SAVE_STUDENT_SUCCESS', currentStudent })

        setSaving(false)
        setSaveSuccess(true)
        setTimeout(() => setSaveSuccess(false), 1000)
      } catch (error) {
        console.error('Error while trying to save changes.', error)
        setSaving(false)
        setSaveSuccess(false)
        setErrors({ signup: error.reason })
      }
    }
  }

  const _onImageChosen = (e) => {
    const _file = e.target.files[0]
    const _url = URL.createObjectURL(_file)
    setDisplayFile(_file)
    setDisplayURL(_url)
    setErrors(_errors => ({ ..._errors, displayImage: null }))
  }

  const [firstName, ...rest] = fullName.split(' ')
  const lastName = rest.join(' ')
  const buttonDisabled = (firstName === _student.firstName) &&
    (lastName === _student.lastName) &&
    (email === _student.email) &&
    (identificationId === _student.identificationId) &&
    (address === _student.address) &&
    (phone === _student.phone) &&
    (schoolName === _student.schoolName) &&
    (majorId === get(find(majors, ({ majorName }) => _student.majorName === majorName), 'majorId')) &&
    !displayFile

  return (
    <div>
      <SignupHead>แก้ไขข้อมูลส่วนตัว</SignupHead>
      <SignupBox>
        <MobileSignupHead>แก้ไขข้อมูลส่วนตัว</MobileSignupHead>
        <FormGroup error={errors.fullName}>
          <FormInput
            ref={fullNameRef}
            placeholder='ชื่อ-สกุล'
            onFocus={() => setFocusKey('user')}
            onBlur={() => setFocusKey()}
            value={fullName}
            onChange={e => setFullName(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'user'}>
            <SVG src='/svg/icon-user.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(fullNameRef, 'fullName')}>
            {errors.fullName}
          </div>
        </FormGroup>
        <FormGroup error={errors.address}>
          <FormTextarea
            ref={addressRef}
            placeholder='กรอกที่อยู่ให้ถูกต้องสำหรับจัดส่งหนังสือ'
            onFocus={() => setFocusKey('address')}
            onBlur={() => setFocusKey()}
            value={address}
            onChange={e => setAddress(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'address'}>
            <SVG src='/svg/icon-address.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(addressRef, 'address')}>
            {errors.address}
          </div>
        </FormGroup>
        <FormGroup error={errors.schoolId}>
          <AutocompleteWrapper>
            <Autocomplete
              ref={schoolIdRef}
              inputProps={{
                onFocus: () => setFocusKey('school'),
                onBlur: () => setFocusKey(),
                disabled: schoolsLoading,
                placeholder: SCHOOL_PLACEHOLDER
              }}
              shouldItemRender={(item, value) => value && item.schoolName.toLowerCase().indexOf(value.toLowerCase()) > -1}
              getItemValue={(item) => item.schoolName}
              items={schools}
              renderMenu={(items, value, style) => {
                const visible = value && schools.some(({ schoolName }) => schoolName.toLowerCase().indexOf(value.toLowerCase()) > -1)
                return <AutocompleteMenu style={{ style, display: visible ? 'block' : 'none' }} children={items} />
              }}
              renderItem={(item, isHighlighted) =>
                <AutocompleteMenuItem key={item.schoolId} isHighlighted={isHighlighted}>
                  {item.schoolName}
                </AutocompleteMenuItem>
              }
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              onSelect={(_schoolName) => setSchoolName(_schoolName)}
            />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'school'}>
              {schoolsLoading ? <Spinner /> : <SVG src='/svg/icon-school.svg' />}
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(schoolIdRef, 'schoolId')}>
              {errors.schoolId}
            </div>
          </AutocompleteWrapper>
        </FormGroup>
        <FormGroup error={errors.majorId}>
          <FormSelect
            ref={majorIdRef}
            placeholder='ระดับชั้น'
            onClick={() => _onErrorClear(majorIdRef, 'majorId')}
            onFocus={() => setFocusKey('grade')}
            onBlur={() => setFocusKey()}
            value={majorId}
            disabled={majorsLoading}
            onChange={e => setMajorId(e.target.value === MAJOR_PLACEHOLDER ? '' : e.target.value)}>
            <option>{errors.majorId ? 'กรุณาระบุ' : ''}{MAJOR_PLACEHOLDER}</option>
            {majors.map(({ majorId, majorName }) =>
              <option key={majorId} value={majorId}>{majorName}</option>
            )}
          </FormSelect>
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'grade'}>
            {majorsLoading ? <Spinner /> : <SVG src='/svg/icon-grade.svg' />}
          </IconWrapper>
          {!majorId && (
            <IconWrapper
              style={{ left: 'initial', right: 10 }}
              className='icon-wrapper'
              isFocused={focusKey === 'grade'}>
              {majorsLoading ? null : <SVG src='/svg/icon-chevron-down.svg' />}
            </IconWrapper>
          )}
        </FormGroup>
        <FormGroup error={errors.phone}>
          <FormInput
            ref={phoneRef}
            placeholder='เบอร์โทรศัพท์'
            onFocus={() => setFocusKey('phone')}
            onBlur={() => setFocusKey()}
            value={phone}
            onChange={e => {
              const _num = e.target.value
              if (_num && isNaN(_num)) return
              if (_num.length > 10) return
              setPhone(_num)
            }} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'phone'}>
            <SVG src='/svg/icon-phone.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(phoneRef, 'phone')}>
            {errors.phone}
          </div>
        </FormGroup>
        <FormGroup error={errors.identificationId}>
          <FormInput
            ref={identificationIdRef}
            placeholder='เลขบัตรประชาชน'
            onFocus={() => setFocusKey('card')}
            onBlur={() => setFocusKey()}
            value={identificationId}
            onChange={e => {
              const _id = e.target.value
              if (_id && isNaN(_id)) return
              if (_id.length > 13) return
              setIdentificationId(_id)
            }} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'card'}>
            <SVG src='/svg/icon-card.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(identificationIdRef, 'identificationId')}>
            {errors.identificationId}
          </div>
        </FormGroup>
        <FormGroup error={errors.email}>
          <FormInput
            ref={emailRef}
            placeholder='อีเมล'
            onFocus={() => setFocusKey('email')}
            onBlur={() => setFocusKey()}
            value={email}
            onChange={e => setEmail(e.target.value)} />
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'email'}>
            <SVG src='/svg/icon-email.svg' />
          </IconWrapper>
          <div className='error-text'
            onClick={() => _onErrorClear(emailRef, 'email')}>
            {errors.email}
          </div>
        </FormGroup>
        <FormGroup error={errors.displayImage}>
          <ImageUploader className='container' onClick={() => imageRef.current.click()}>
            <div>{errors.displayImage && 'กรุณา'}อัพโหลดรูปนักเรียน</div>
            <button>เลือกรูปภาพ</button>
            <input type='file' style={{ display: 'none' }} accept='image/*' ref={imageRef} onChange={_onImageChosen} />
            {Boolean(displayURL) && <img alt='student-pic' src={displayURL} />}
          </ImageUploader>
          <IconWrapper className='icon-wrapper' isFocused={focusKey === 'image'}>
            <SVG src='/svg/icon-image.svg' />
          </IconWrapper>
        </FormGroup>
        <FormButton
          style={{
            opacity: saveSuccess ? 0.7 : buttonDisabled ? 0.4 : 1,
            cursor: saveSuccess ? 'default' : 'pointer'
          }}
          isLoading={saving}
          onClick={_onSave}>
          <Spinner />
          <SVG src={saveSuccess ? '/svg/icon-check.svg' : '/svg/icon-save.svg'} />
          <span>บันทึกข้อมูล{saveSuccess && 'สำเร็จ'}</span>
        </FormButton>
        {errors.signup && <SignupError>{errors.signup}</SignupError>}
      </SignupBox>
    </div>
  )
}

export default EditInfo

const SignupHead = styled.h1`
  > div:before { content: '*'; }
  @media(max-width: 425px) {
    display: none;
  }
`
const MobileSignupHead = styled.div`
  color: ${getTheme`colors.red`};
  > div:before { content: '*'; }
  text-align: left;
  margin-bottom: 5px;
  font-weight: 700;
  @media(min-width: 426px) {
    display: none;
  }
`
const SignupBox = styled(ContentBox)`
  @media(max-width: 425px) {
    width: calc(100vw - 60px);
    padding: 15px 15px 30px;
    margin: 0 15px;
    margin-bottom: 40px;
  }
`
const AutocompleteWrapper = styled.div`
  > div { display: block !important; }
  input {
    display: block;
    height: 40px;
    border: 1px solid ${getTheme`colors.gray`};
    outline: none;
    text-indent: 40px;
    padding: 0px;
    width: calc(100% - 2px);
    border-radius: 5px;
    font-size: 14px;
    &:disabled {
      background-color: #e6e6e6;
    }
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: ${getTheme`colors.gray`};
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: ${getTheme`colors.gray`};
    opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: ${getTheme`colors.gray`};
    opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${getTheme`colors.gray`};
    }
    &:focus {
      border-color: ${getTheme`colors.secondary`};
    }
  }
`
const AutocompleteMenu = styled.div`
  z-index: 9999;
  margin-left: 20px;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid ${getTheme`colors.secondary`};
  max-height: 200px;
  overflow-y: auto;
`
const AutocompleteMenuItem = styled.div`
  border-bottom: 1px solid ${getTheme`colors.blue`};
  line-height: 40px;
  color: black;
  font-size: 14px;
  text-indent: 20px;
  text-align: left;
  cursor: pointer;
  background-color: ${({ isHighlighted }) => isHighlighted ? theme.colors.blue : 'white'};
`

const SignupError = styled.div`
  color: #ed1c24;
  margin-top: 20px;
  &:before {
    content: '*';
  }
  @media(min-width: 426px) {
    font-size: 16px;
  }
  @media(max-width: 425px) {
    font-size: 14px;
  }
`
const ImageUploader = styled.div`
  display: block;
  text-indent: 40px;
  text-align: left;
  border: 1px solid ${getTheme`colors.gray`};
  border-radius: 5px;
  color: ${getTheme`colors.gray`};
  font-size: 14px;
  padding: 10px 0px;

  button {
    margin-left: 40px;
    margin-top: 5px;
    cursor: pointer;
  }

  img {
    display: block;
    object-fit: cover;
    margin: 20px auto;
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
`
