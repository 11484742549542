import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getTheme } from '../helpers'

export default ({ value: selectedValue, options, onSelect }) => {
  const [value, setValue] = useState(selectedValue)
  useEffect(() => setValue(selectedValue), [selectedValue])

  return (
    <RadioGroup>
      {(options || []).map((option) =>
        <RadioButton key={option.value} onClick={() => onSelect(option.value)}>
          <Dot active={option.value === value} />
          <RadioTitle>{option.title}</RadioTitle>
        </RadioButton>
      )}
    </RadioGroup>
  )
}

const RadioGroup = styled.div`
  cursor: pointer;
  white-space: initial;
`
const RadioButton = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
`
const Dot = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid;
  display: inline-block;
  vertical-align: top;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 3px; left: 3px; right: 3px; bottom: 3px;
    border-radius: 50%;
  }
  border-radius: 50%;
  ${({ active, theme }) => active ? `
    background-color: white;
    border-color: ${theme.colors.secondary};
    &:after {
      background-color: ${theme.colors.secondary};
    }
  ` : `
    background-color: white;
    border-color: ${theme.colors.gray};
  `}
`
const RadioTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  line-height: 18px;
  color: ${getTheme`colors.gray`};
  font-family: ${getTheme`font1`};
  font-size: 14px;
`
