import React, { useState, useRef, useEffect } from "react";
import { getTheme, callAPI, formatComma } from "helpers";
import styled from "styled-components";
import config from "config";
import { useSelector } from "react-redux";
import { get, find, padStart, uniqBy } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";

// Components
import theme from "theme";
import SVG from "react-inlinesvg";
import Spinner from "components/Spinner";
import RadioGroup from "components/RadioGroup";
import ContentBox from "components/ContentBox";
import FormGroup from "components/FormGroup";
import IconWrapper from "components/IconWrapper";
import FormSelect from "components/FormSelect";
import FormInput from "components/FormInput";
import FormButton from "components/FormButton";
import FormTextarea from "components/FormTextarea";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";

// Hooks
import useAvailableClasses from "hooks/useAvailableClasses";
import useBranches from "hooks/useBranches";
import usePaymentLiveFeeds from "hooks/usePaymentLiveFeeds";
import usePostalCodes from "hooks/usePostalCodes";

import "react-datepicker/dist/react-datepicker.css";

const CLASS_CODE_PLACEHOLDER = "คอร์สที่ต้องการสมัคร";
const BRANCH_CODE_PLACEHOLDER = "สาขาที่ต้องการสมัคร";
const DELIVERY_BRANCH_CODE_PLACEHOLDER = "สาขาที่ต้องการรับหนังสือ";
const POSTAL_CODE_PLACEHOLDER = "รหัสไปรษณีย์";

const classTypeNameMap = {
  Feed: "ถ่ายทอด",
  Live: "สด",
};

const Payment = () => {
  const { classesLoading, classes } = useAvailableClasses();
  const { branchesLoading, branches } = useBranches();
  const { postalCodes } = usePostalCodes();

  const currentStudent = useSelector((state) =>
    get(state, "app.currentStudent")
  );

  const classCodeRef = useRef();
  const discountCodeRef = useRef();
  const branchCodeRef = useRef();
  const deliveryBranchCodeRef = useRef();
  const pickupDateRef = useRef();
  const addressRef = useRef();
  const postalCodeRef = useRef();
  const phoneRef = useRef();
  const phone2Ref = useRef();

  const [focusKey, setFocusKey] = useState();
  const [classCode, setClassCode] = useState();
  const [courseType, setCourseType] = useState("online");
  const [branchCode, setBranchCode] = useState();
  const [paymentMethod, setPaymentMethod] = useState("transfer");
  const [discountCode, setDiscountCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  // const [deliveryMethod, setDeliveryMethod] = useState('home')
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryBranchCode, setDeliveryBranchCode] = useState();
  const [pickupDate, setPickupDate] = useState();
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");

  const _studentAddress = get(currentStudent, "address") || "";
  const _postalCode = get(currentStudent, "postalCode") || "";

  const studentHasPostalCode =
    (_studentAddress.slice(-5).length === 5 &&
      !isNaN(_studentAddress.slice(-5))) ||
    (_postalCode && !isNaN(_postalCode));
  const studentHasPhone = get(currentStudent, "phone") || "";
  const [addressType, setAddressType] = useState(
    studentHasPostalCode ? "former" : "new"
  );
  useEffect(() => setAddressType(studentHasPostalCode ? "former" : "new"), [
    studentHasPostalCode,
  ]);

  useEffect(() => setClassCode(""), [branchCode]);
  const { liveFeedClasses, classIsFull } = usePaymentLiveFeeds(classCode);

  useEffect(
    () =>
      setErrors((_errors) => ({
        ..._errors,
        address: false,
        postalCode: false,
        phone: false,
      })),
    [addressType]
  );

  const _onErrorClear = (ref, key) => {
    ref.current.focus();
    setErrors((_errors) => ({ ..._errors, [key]: null }));
  };

  const [discountValue, setDiscountValue] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [discountVerified, setVerified] = useState(false);
  const [discountError, setDiscountError] = useState("");
  const _onDiscountSearch = async () => {
    if (!discountCode) {
      setVerified(false);
      setDiscountValue();
      setDiscountError("");
    } else {
      try {
        setVerifying(true);
        const selectedClass = find(
          classes,
          ({ classCode: _code }) => _code === classCode
        );
        const {
          result: { discountValue },
        } = await callAPI({
          url: `/discounts/${discountCode}`,
          query: {
            courseCode: get(selectedClass, "courseCode"),
            branchCode: get(selectedClass, "branchCode") || 10,
          },
        });
        setDiscountValue(discountValue);
        setDiscountError("");
        setVerifying(false);
        setVerified(true);
      } catch (error) {
        console.error("Error while trying to verify a discount code.", error);
        setVerifying(false);
        setVerified(true);
        setDiscountValue();
        setDiscountError(error.reason);
      }
    }
  };

  const formatMoney = (_number) => {
    const [integer, decimal] = `${_number}`.split(".");
    return `${formatComma(integer)}.${padStart(decimal || 0, 2, "0")}`;
  };

  const _onSubmit = async () => {
    if (submitting) return;
    const _errors = {
      classCode: !classCode && "กรุณาระบุคอร์สที่ต้องการสมัคร",
      branchCode:
        courseType === "offline" &&
        !branchCode &&
        "กรุณาระบุสาขาที่ต้องการสมัคร",
      deliveryBranchCode:
        deliveryMethod === "branch" &&
        !deliveryBranchCode &&
        "กรุณาระบุสาขาที่ต้องการรับหนังสือ",
      pickupDate:
        deliveryMethod === "branch" &&
        !pickupDate &&
        "กรุณาระบุวันที่รับหนังสือ",
      address:
        deliveryMethod === "home" &&
        addressType === "new" &&
        !address &&
        "กรุณาระบุที่อยู่สำหรับจัดส่งหนังสือ",
      postalCode:
        deliveryMethod === "home" &&
        addressType === "new" &&
        !postalCode &&
        "กรุณาระบุรหัสไปรษณีย์สำหรับจัดส่งหนังสือ",
      phone:
        deliveryMethod === "home" &&
        addressType === "new" &&
        (!phone
          ? "กรุณาระบุเบอร์สำหรับติดต่อ"
          : phone.length < 10 && "กรุณากรอกเบอร์สำหรับติดต่อให้ครบ 10 หลัก"),
      phone2:
        deliveryMethod === "home" &&
        addressType === "new" &&
        phone2 &&
        phone2.length < 10 &&
        "กรุณากรอกเบอร์โทรสำรองให้ครบ 10 หลัก",
    };
    setErrors(_errors);
    if (!Object.keys(_errors).some((key) => _errors[key])) {
      try {
        setSubmitting(true);

        const studentAddress = get(currentStudent, "address") || "";
        const studentPhone = get(currentStudent, "phone") || "";
        const studentPhone2 = get(currentStudent, "phone2") || "";

        const { result } = await callAPI({
          method: "POST",
          url: "/orders",
          body: {
            classCode,
            discountCode: discountValue ? discountCode : null,

            deliveryMethod,
            deliveryAddress:
              deliveryMethod === "home"
                ? addressType === "former"
                  ? studentAddress
                  : address
                : null,
            deliveryPostalCode:
              deliveryMethod === "home"
                ? addressType === "former"
                  ? _postalCode || studentAddress.slice(-5)
                  : postalCode
                : null,
            deliveryBranchCode:
              deliveryMethod === "branch" ? deliveryBranchCode : null,
            deliveryPhone:
              deliveryMethod === "home"
                ? addressType === "former"
                  ? studentPhone
                  : phone
                : null,
            deliveryPhone2:
              deliveryMethod === "home"
                ? addressType === "former"
                  ? studentPhone2
                  : phone2
                : null,
            pickupDate: deliveryMethod === "branch" ? pickupDate : null,

            paymentInfo:
              paymentMethod === "transfer"
                ? {
                    payment_option: "Q",
                    qr_type: "PP",
                    result_url_1: config.resultURL1,
                  }
                : {
                    payment_option: "C",
                    result_url_1: config.resultURL1,
                  },
          },
        });
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", config.paymentURL);

        Object.keys(result).forEach((key) => {
          const input = document.createElement("input");
          input.setAttribute("type", "input");
          input.setAttribute("name", `${key}`);
          input.setAttribute("value", `${result[key]}`);
          form.appendChild(input);
        });
        document.body.append(form);
        form.submit();
      } catch (error) {
        console.error("Error while trying to submit an order.", error);
        setSubmitting(false);
      }
    }
  };

  const actualPrice =
    get(
      find(
        classes.concat(liveFeedClasses),
        (_class) => _class.classCode === classCode
      ),
      "classFee"
    ) || 0;
  const finalPrice = actualPrice - (discountValue || 0);
  const noPicture = !get(currentStudent, "picturePath");

  const optionMap = {
    "live-feed": liveFeedClasses.filter(
      ({ branchCode: _classBranchCode }) => _classBranchCode === branchCode
    ),
    online: classes.filter(
      ({ classTypeName, branchCode: _classBranchCode }) =>
        courseType === "online" && classTypeName === "Online"
    ),
    offline: classes.filter(
      ({ classTypeName, branchCode: _classBranchCode }) =>
        _classBranchCode === branchCode && classTypeName !== "Online"
    ),
  };

  return (
    <div>
      <PaymentHead>เลือกคอร์สที่ต้องการสมัคร</PaymentHead>
      <PaymentBox>
        <PaymentBoxBody>
          <FormGroupLabel>ชื่อนักเรียนที่สมัครเรียน</FormGroupLabel>
          <FormGroupWrapper>
            <FormGroup error={errors.fullName}>
              <FormInput
                disabled
                onFocus={() => setFocusKey("user")}
                onBlur={() => setFocusKey()}
                value={`${get(currentStudent, "firstName")} ${get(
                  currentStudent,
                  "lastName"
                )}`}
              />
              <IconWrapper
                className="icon-wrapper"
                isFocused={focusKey === "user"}
              >
                <SVG src="/svg/icon-user.svg" />
              </IconWrapper>
            </FormGroup>
          </FormGroupWrapper>
          <FormGroupLabel>ประเภทคอร์สเรียน</FormGroupLabel>
          <FormGroupWrapper style={{ textAlign: "left" }}>
            <RadioGroup
              onSelect={(value) => {
                setCourseType(value);
                setClassCode("");
                setBranchCode(value === "live-feed" ? "5" : undefined);
              }}
              value={courseType}
              options={[{ title: "เรียนออนไลน์", value: "online" }].concat(
                liveFeedClasses.length
                  ? [{ title: "รอบสด/ถ่ายทอดสด", value: "live-feed" }]
                  : []
              )}
            />
          </FormGroupWrapper>
          {courseType !== "online" && courseType !== "live-feed" && (
            <div>
              <FormGroupLabel>สาขา</FormGroupLabel>
              <FormGroupWrapper>
                <FormGroup error={errors.branchCode}>
                  <FormSelect
                    ref={branchCodeRef}
                    placeholder="เลือกสาขา"
                    onClick={() => _onErrorClear(branchCodeRef, "branchCode")}
                    onFocus={() => setFocusKey("branchCode")}
                    onBlur={() => setFocusKey()}
                    value={branchCode}
                    disabled={branchesLoading}
                    onChange={(e) =>
                      setBranchCode(
                        e.target.value === BRANCH_CODE_PLACEHOLDER
                          ? ""
                          : e.target.value
                      )
                    }
                  >
                    <option>
                      {errors.branchCode ? "กรุณาระบุ" : ""}
                      {BRANCH_CODE_PLACEHOLDER}
                    </option>
                    {branches.map(({ branchCode, branchName }) => (
                      <option key={branchCode} value={branchCode}>
                        {branchName}
                      </option>
                    ))}
                  </FormSelect>
                  <IconWrapper
                    className="icon-wrapper"
                    isFocused={focusKey === "branchCode"}
                  >
                    {branchesLoading ? (
                      <Spinner />
                    ) : (
                      <SVG src="/svg/icon-building.svg" />
                    )}
                  </IconWrapper>
                  {!branchCode && (
                    <IconWrapper
                      style={{ left: "initial", right: 10 }}
                      className="icon-wrapper"
                      isFocused={focusKey === "branchCode"}
                    >
                      {branchesLoading ? null : (
                        <SVG src="/svg/icon-chevron-down.svg" />
                      )}
                    </IconWrapper>
                  )}
                </FormGroup>
              </FormGroupWrapper>
            </div>
          )}
          <FormGroupLabel>คอร์สเรียน</FormGroupLabel>
          <FormGroupWrapper>
            <FormGroup error={errors.classCode}>
              <FormSelect
                ref={classCodeRef}
                placeholder="เลือกคอร์สที่ต้องการสมัคร"
                onClick={() => _onErrorClear(classCodeRef, "classCode")}
                onFocus={() => setFocusKey("classCode")}
                onBlur={() => setFocusKey()}
                value={classCode}
                disabled={
                  classesLoading ||
                  (["offline, live-feed"].includes(courseType) && !branchCode)
                }
                onChange={(e) =>
                  setClassCode(
                    e.target.value === CLASS_CODE_PLACEHOLDER
                      ? ""
                      : e.target.value
                  )
                }
              >
                <option>
                  {errors.classCode ? "กรุณาระบุ" : ""}
                  {CLASS_CODE_PLACEHOLDER}
                </option>
                {uniqBy(optionMap[courseType], "classCode").map(
                  ({ classCode, className, classTypeName }) => {
                    if (courseType === "online")
                      return (
                        <option key={classCode} value={classCode}>
                          {className}
                        </option>
                      );
                    return (
                      <option key={classCode} value={classCode}>
                        {classCode}: {className}{" "}
                        {classTypeNameMap[classTypeName]
                          ? `(${classTypeNameMap[classTypeName]})`
                          : ""}
                      </option>
                    );
                  }
                )}
              </FormSelect>
              <IconWrapper
                className="icon-wrapper"
                isFocused={focusKey === "classCode"}
              >
                {classesLoading ? (
                  <Spinner />
                ) : (
                  <SVG src="/svg/icon-book.svg" />
                )}
              </IconWrapper>
              {!classCode && (
                <IconWrapper
                  style={{ left: "initial", right: 10 }}
                  className="icon-wrapper"
                  isFocused={focusKey === "classCode"}
                >
                  {classesLoading ? null : (
                    <SVG src="/svg/icon-chevron-down.svg" />
                  )}
                </IconWrapper>
              )}
            </FormGroup>
          </FormGroupWrapper>
          <FormGroupLabel>ช่องทางการชำระเงิน</FormGroupLabel>
          <FormGroupWrapper style={{ textAlign: "left" }}>
            <RadioGroup
              onSelect={(value) => setPaymentMethod(value)}
              value={paymentMethod}
              options={[
                { title: "ผ่านบัญชีธนาคาร", value: "transfer" },
                { title: "ผ่านบัตรเครดิต", value: "credit-card" },
              ]}
            />
          </FormGroupWrapper>
          <FormGroupLabel>รหัสส่วนลด</FormGroupLabel>
          <FormGroupWrapper className={discountValue ? " code-exists" : ""}>
            <FormGroup>
              <FormInput
                ref={discountCodeRef}
                placeholder="ใส่โค้ดรหัสส่วนลด (ถ้ามี)"
                onFocus={() => setFocusKey("discountCode")}
                onBlur={() => {
                  setFocusKey();
                }}
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
              />
              <IconWrapper
                className="icon-wrapper"
                isFocused={focusKey === "discountCode"}
              >
                {verifying ? (
                  <Spinner />
                ) : (
                  <SVG
                    src={`/svg/icon-${discountValue ? "check" : "tag"}.svg`}
                  />
                )}
              </IconWrapper>
            </FormGroup>
            <ButtonRow>
              <div>
                {discountVerified && (
                  <DiscountText className="discount-text">
                    {discountValue
                      ? `ส่วนลดมูลค่า ${formatMoney(discountValue)} บาท`
                      : discountError}
                  </DiscountText>
                )}
              </div>
              <BtnConfirm>
                <FormButton
                  disabled={!classCode || !discountCode}
                  onClick={_onDiscountSearch}
                >
                  ยืนยัน
                </FormButton>
              </BtnConfirm>
            </ButtonRow>
          </FormGroupWrapper>
          {false && (
            <div>
              <FormGroupLabel>ช่องทางการรับหนังสือ</FormGroupLabel>
              <FormGroupWrapper style={{ textAlign: "left" }}>
                <RadioGroup
                  onSelect={(value) => setDeliveryMethod(value)}
                  value={deliveryMethod}
                  options={[
                    { title: "รับหนังสือที่สาขา", value: "branch" },
                    { title: "ส่งหนังสือถึงบ้าน", value: "home" },
                  ]}
                />
              </FormGroupWrapper>
            </div>
          )}
          {deliveryMethod === "branch" ? (
            <div>
              <FormGroupLabel>รับหนังสือที่สาขา</FormGroupLabel>
              <FormGroupWrapper>
                <FormGroup error={errors.deliveryBranchCode}>
                  <FormSelect
                    ref={deliveryBranchCodeRef}
                    placeholder="เลือกสาขา"
                    onClick={() =>
                      _onErrorClear(deliveryBranchCodeRef, "deliveryBranchCode")
                    }
                    onFocus={() => setFocusKey("deliveryBranchCode")}
                    onBlur={() => setFocusKey()}
                    value={deliveryBranchCode}
                    disabled={branchesLoading}
                    onChange={(e) =>
                      setDeliveryBranchCode(
                        e.target.value === DELIVERY_BRANCH_CODE_PLACEHOLDER
                          ? ""
                          : e.target.value
                      )
                    }
                  >
                    <option>
                      {errors.deliveryBranchCode ? "กรุณาระบุ" : ""}
                      {DELIVERY_BRANCH_CODE_PLACEHOLDER}
                    </option>
                    {branches.map(({ branchCode, branchName }) => (
                      <option key={branchCode} value={branchCode}>
                        {branchName}
                      </option>
                    ))}
                  </FormSelect>
                  <IconWrapper
                    className="icon-wrapper"
                    isFocused={focusKey === "deliveryBranchCode"}
                  >
                    {branchesLoading ? (
                      <Spinner />
                    ) : (
                      <SVG src="/svg/icon-building.svg" />
                    )}
                  </IconWrapper>
                  {!branchCode && (
                    <IconWrapper
                      style={{ left: "initial", right: 10 }}
                      className="icon-wrapper"
                      isFocused={focusKey === "deliveryBranchCode"}
                    >
                      {branchesLoading ? null : (
                        <SVG src="/svg/icon-chevron-down.svg" />
                      )}
                    </IconWrapper>
                  )}
                </FormGroup>
              </FormGroupWrapper>
              <FormGroupLabel>วันที่รับหนังสือ</FormGroupLabel>
              <FormGroupWrapper>
                <FormGroup error={errors.pickupDate}>
                  <DatePickerWrapper ref={pickupDateRef}>
                    <DatePicker
                      placeholderText="ระบุวันที่รับหนังสือ"
                      dateFormat="dd/MM/yyyy"
                      minDate={moment().add(7, "days").toDate()}
                      selected={
                        pickupDate
                          ? moment(pickupDate, "YYYY-MM-DD").toDate()
                          : null
                      }
                      onChange={(_date) =>
                        setPickupDate(moment(_date).format("YYYY-MM-DD"))
                      }
                    />
                  </DatePickerWrapper>
                  <IconWrapper className="icon-wrapper">
                    <SVG src="/svg/icon-calendar.svg" />
                  </IconWrapper>
                  <IconWrapper
                    style={{ left: "initial", right: 10 }}
                    className="icon-wrapper"
                  >
                    <SVG src="/svg/icon-chevron-down.svg" />
                  </IconWrapper>
                  <div
                    className="error-text"
                    onClick={() => _onErrorClear(pickupDateRef, "pickupDate")}
                  >
                    {errors.pickupDate}
                  </div>
                </FormGroup>
              </FormGroupWrapper>
            </div>
          ) : deliveryMethod === "home" ? (
            <div>
              <FormGroupLabel>
                <div>
                  *กรุณาตรวจสอบที่อยู่ในการจัดส่งหนังสือทุกครั้งก่อนชำระเงิน
                </div>
                <div>*ทางโรงเรียนจะจัดส่งหนังสือตามที่อยู่ที่กรอกไว้</div>
              </FormGroupLabel>
              {studentHasPostalCode && studentHasPhone && (
                <FormGroupWrapper
                  style={{
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  <RadioGroup
                    onSelect={(value) => setAddressType(value)}
                    value={addressType}
                    options={[
                      {
                        title: "ใช้ที่อยู่และเบอร์โทรที่ลงทะเบียนในการจัดส่ง",
                        value: "former",
                      },
                      { title: "แก้ไขที่อยู่และเบอร์โทรใหม่", value: "new" },
                    ]}
                  />
                </FormGroupWrapper>
              )}
              <FormGroupWrapper>
                <FormGroup error={errors.address}>
                  <FormTextarea
                    ref={addressRef}
                    disabled={addressType === "former"}
                    placeholder="กรอกที่อยู่ให้ถูกต้องสำหรับจัดส่งหนังสือ"
                    onFocus={() => setFocusKey("address")}
                    onBlur={() => setFocusKey()}
                    value={
                      addressType === "former"
                        ? get(currentStudent, "address")
                        : address
                    }
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <IconWrapper
                    className="icon-wrapper"
                    isFocused={focusKey === "address"}
                  >
                    <SVG src="/svg/icon-address.svg" />
                  </IconWrapper>
                  <div
                    className="error-text"
                    onClick={() => _onErrorClear(addressRef, "address")}
                  >
                    {errors.address}
                  </div>
                </FormGroup>
                <FormGroup error={errors.postalCode}>
                  <AutocompleteWrapper>
                    <Autocomplete
                      ref={postalCodeRef}
                      inputProps={{
                        disabled: addressType === "former",
                        onFocus: () => setFocusKey("postalCode"),
                        onBlur: () => setFocusKey(),
                        placeholder: POSTAL_CODE_PLACEHOLDER,
                      }}
                      shouldItemRender={(item, value) =>
                        value && item.indexOf(value) === 0
                      }
                      getItemValue={(item) => item}
                      items={postalCodes}
                      renderMenu={(items, value, style) => {
                        const visible =
                          value &&
                          postalCodes.some((code) => code.indexOf(value) === 0);
                        return (
                          <AutocompleteMenu
                            style={{
                              style,
                              display: visible ? "block" : "none",
                            }}
                            children={items}
                          />
                        );
                      }}
                      renderItem={(item, isHighlighted) => (
                        <AutocompleteMenuItem
                          key={item}
                          isHighlighted={isHighlighted}
                        >
                          {item}
                        </AutocompleteMenuItem>
                      )}
                      value={
                        addressType === "former"
                          ? _postalCode ||
                            (get(currentStudent, "address") || "").slice(-5)
                          : postalCode
                      }
                      onChange={(e) => setPostalCode(e.target.value)}
                      onSelect={(_postalCode) => setPostalCode(_postalCode)}
                    />
                    <IconWrapper
                      className="icon-wrapper"
                      isFocused={focusKey === "postalCode"}
                    >
                      <SVG src="/svg/icon-address.svg" />
                    </IconWrapper>
                    <div
                      className="error-text"
                      onClick={() => _onErrorClear(postalCodeRef, "postalCode")}
                    >
                      {errors.postalCode}
                    </div>
                  </AutocompleteWrapper>
                </FormGroup>
                <FormGroup error={errors.phone}>
                  <FormInput
                    ref={phoneRef}
                    disabled={addressType === "former"}
                    placeholder="เบอร์โทรศัพท์"
                    onFocus={() => setFocusKey("phone")}
                    onBlur={() => setFocusKey()}
                    value={
                      addressType === "former"
                        ? get(currentStudent, "phone")
                        : phone
                    }
                    onChange={(e) => {
                      const _num = e.target.value;
                      if (_num && isNaN(_num)) return;
                      if (_num.length > 10) return;
                      setPhone(_num);
                    }}
                  />
                  <IconWrapper
                    className="icon-wrapper"
                    isFocused={focusKey === "phone"}
                  >
                    <SVG src="/svg/icon-phone.svg" />
                  </IconWrapper>
                  <div
                    className="error-text"
                    onClick={() => _onErrorClear(phoneRef, "phone")}
                  >
                    {errors.phone}
                  </div>
                </FormGroup>
                <FormGroup error={errors.phone2}>
                  <FormInput
                    ref={phone2Ref}
                    disabled={addressType === "former"}
                    placeholder="เบอร์โทรศัพท์ (สำรอง)"
                    onFocus={() => setFocusKey("phone2")}
                    onBlur={() => setFocusKey()}
                    value={
                      addressType === "former"
                        ? get(currentStudent, "phone2")
                        : phone2
                    }
                    onChange={(e) => {
                      const _num = e.target.value;
                      if (_num && isNaN(_num)) return;
                      if (_num.length > 10) return;
                      setPhone2(_num);
                    }}
                  />
                  <IconWrapper
                    className="icon-wrapper"
                    isFocused={focusKey === "phone2"}
                  >
                    <SVG src="/svg/icon-phone.svg" />
                  </IconWrapper>
                  <div
                    className="error-text"
                    onClick={() => _onErrorClear(phone2Ref, "phone2")}
                  >
                    {errors.phone2}
                  </div>
                </FormGroup>
              </FormGroupWrapper>
            </div>
          ) : null}
        </PaymentBoxBody>
        <PaymentBoxFooter>
          {classCode && (
            <div>
              <FooterLabel>
                <p>ยอดที่ต้องชำระ</p>
                <p>รวมทั้งสิ้น</p>
              </FooterLabel>
              <FooterValue>
                {Boolean(discountValue) && (
                  <span>฿{formatMoney(actualPrice)}</span>
                )}
                <strong>฿{formatMoney(finalPrice)}</strong>
              </FooterValue>
            </div>
          )}
          <FooterRemark>
            <p>
              *จ่ายผ่าน QR บัญชีธนาคารมีค่าบริการ 5 บาท
              และจ่ายผ่านบัตรเครดิตมีค่าบริการ 3%
            </p>
            {false && (
              <p>
                *การจัดส่งหนังสือในพื้นที่กรุงเทพฯและปริมณฑลมีค่าบริการ 50 บาท
              </p>
            )}
            {false && (
              <p>*การจัดส่งหนังสือในพื้นที่ต่างจังหวัดมีค่าบริการ 70 บาท</p>
            )}
          </FooterRemark>
        </PaymentBoxFooter>
      </PaymentBox>
      {noPicture && (
        <DisabledText>
          คุณยังไม่มีรูปภาพนักเรียน{" "}
          <Link to="/edit-info">คลิกเพื่ออัพเดทข้อมูล</Link>
        </DisabledText>
      )}
      {classIsFull && <DisabledText>คอร์สเรียนเต็มแล้ว</DisabledText>}
      <BtnPay
        disabled={noPicture || classIsFull}
        isLoading={submitting}
        onClick={_onSubmit}
      >
        <Spinner />
        <SVG src="/svg/icon-credit.svg" />
        <span>ชำระเงิน</span>
      </BtnPay>
    </div>
  );
};

export default Payment;

const FormGroupLabel = styled.div`
  font-size: 14px;
  text-align: left;
`;
const FormGroupWrapper = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }

  &.code-exists {
    input {
      color: ${getTheme`colors.green`};
      border-color: ${getTheme`colors.green`};
    }
    .icon-wrapper > svg > path {
      fill: ${getTheme`colors.green`};
    }
    .discount-text {
      color: ${getTheme`colors.green`};
    }
  }
`;
const PaymentHead = styled.h1`
  @media (max-width: 425px) {
    margin-top: 0px !important;
  }
`;
const PaymentBox = styled(ContentBox)`
  @media (min-width: 426px) {
    padding: 0px;
  }
  @media (max-width: 425px) {
    width: calc(100% - 30px);
    padding: 0px;
    margin: 0 15px;
  }
`;
const PaymentBoxBody = styled.div`
  padding: 20px;
`;
const PaymentBoxFooter = styled.div`
  padding: 20px;
  border-top: 1px solid ${getTheme`colors.blue`};
`;
const FooterLabel = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: left;
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    color: black;
  }
`;
const FooterValue = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: right;
  margin-bottom: 10px;
  > span {
    display: block;
    font-size: 14px;
    color: ${getTheme`colors.gray`};
    text-decoration: line-through;
  }
  > strong {
    display: block;
    font-size: 24px;
    font-weight: 700;
    color: ${getTheme`colors.green`};
  }
`;
const FooterRemark = styled.div`
  font-size: 12px;
  text-align: left;
  white-space: initial;
  color: ${getTheme`colors.gray`};
  p {
    margin: 0;
    padding: 0;
  }
`;
const DiscountText = styled.div`
  font-size: 12px;
  text-align: left;
  white-space: initial;
  color: ${getTheme`colors.red`};
  &:before {
    content: "*";
  }
`;

const BtnPay = styled(FormButton)`
  max-width: 385px;
  @media (max-width: 425px) {
    width: calc(100vw - 30px);
    margin: 30px 15px;
  }
`;
const ButtonRow = styled.div`
  position: relative;
  height: 40px;
`;
const BtnConfirm = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 80px;

  button {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    margin-top: 0px;
  }
`;
const DisabledText = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  white-space: initial;
  color: ${getTheme`colors.red`};
  &:before {
    content: "*";
  }

  a {
    color: ${getTheme`colors.primary`};
    text-decoration: underline;
    cursor: pointer;
  }
`;
const AutocompleteWrapper = styled.div`
  > div {
    display: block !important;
  }
  input {
    display: block;
    height: 40px;
    border: 1px solid ${getTheme`colors.gray`};
    outline: none;
    text-indent: 40px;
    padding: 0px;
    width: calc(100% - 2px);
    border-radius: 5px;
    font-size: 14px;
    &:disabled {
      background-color: #e6e6e6;
    }
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: ${getTheme`colors.gray`};
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: ${getTheme`colors.gray`};
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${getTheme`colors.gray`};
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${getTheme`colors.gray`};
    }
    &:focus {
      border-color: ${getTheme`colors.secondary`};
    }
  }
`;
const AutocompleteMenu = styled.div`
  z-index: 9999;
  margin-left: 20px;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid ${getTheme`colors.secondary`};
  max-height: 200px;
  overflow-y: auto;
`;
const AutocompleteMenuItem = styled.div`
  border-bottom: 1px solid ${getTheme`colors.blue`};
  line-height: 40px;
  color: black;
  font-size: 14px;
  text-indent: 20px;
  text-align: left;
  cursor: pointer;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? theme.colors.blue : "white"};
`;
const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${getTheme`colors.secondary`} !important;
  }
  input {
    display: block;
    height: 40px;
    border: 1px solid ${getTheme`colors.gray`};
    outline: none;
    text-indent: 40px;
    padding: 0px;
    width: calc(100% - 2px);
    border-radius: 5px;
    font-size: 14px;
    &:disabled {
      background-color: #e6e6e6;
    }
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: ${getTheme`colors.gray`};
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: ${getTheme`colors.gray`};
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${getTheme`colors.gray`};
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${getTheme`colors.gray`};
    }
    &:focus {
      border-color: ${getTheme`colors.secondary`};
    }
  }
`;
