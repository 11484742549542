import styled from 'styled-components'
export const Horizontal = styled.div`
  text-align: center;
  white-space: nowrap;
  background-color: ${props => props.theme[props.backgroundColor] || props.backgroundColor};
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: ${props => props.height || '100vh'};
  }
`
export const Vertical = styled.div`
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: center;
  max-width: 100vw;
`
