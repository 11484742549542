import styled from 'styled-components'
export default styled.div`
  position: absolute;
  width: 20px;
  height: 16px;
  top: 12px;
  left: 10px;
  pointer-events: none;
  > svg, .spinner > svg {
    height: 16px;
  }
  ${({ isFocused, theme }) => isFocused ? `
    svg > path, .spinner > svg > path { fill: ${theme.colors.secondary}; }
  ` : `
    svg > path, .spinner > svg > path { fill: ${theme.colors.gray}; }
  `}
`
