import { useState, useEffect } from 'react'
import { callAPI } from 'helpers'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const _storedBranches = useSelector(state => get(state, 'app.branches')) || []
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState(_storedBranches)

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        setLoading(true)
        const { result: _branches } = await callAPI({ url: '/branches' })
        const filteredBranches = _branches.filter(({ branchCode }) => !['0'].includes(`${branchCode}`))
        setBranches(filteredBranches)
        setLoading(false)
        dispatch({ type: 'BRANCHES_UPDATED', branches: filteredBranches })
      } catch (error) {
        console.error('Error while trying to fetch branches.', error)
        setLoading(false)
      }
    }

    if (!_storedBranches.length) { fetchBranches() }
  }, [dispatch, _storedBranches.length])

  return {
    branchesLoading: loading,
    branches
  }
}
