import { useState, useEffect } from 'react'
import { callAPI } from 'helpers'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const _storedMajors = useSelector(state => get(state, 'app.majors')) || []
  const [loading, setLoading] = useState(false)
  const [majors, setMajors] = useState(_storedMajors)

  useEffect(() => {
    const fetchMajors = async () => {
      try {
        setLoading(true)
        const { result: _majors } = await callAPI({ url: '/majors' })
        setMajors(_majors)
        setLoading(false)
        dispatch({ type: 'MAJORS_UPDATED', majors: _majors })
      } catch (error) {
        console.error('Error while trying to fetch majors.', error)
        setLoading(false)
      }
    }

    if (!_storedMajors.length) { fetchMajors() }
  }, [dispatch, _storedMajors.length])

  return {
    majorsLoading: loading,
    majors
  }
}
