import { useState, useEffect } from 'react'
import { callAPI } from 'helpers'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const _storedSchools = useSelector(state => get(state, 'app.schools')) || []
  const [loading, setLoading] = useState(false)
  const [schools, setSchools] = useState(_storedSchools)

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        setLoading(true)
        const { result: _schools } = await callAPI({ url: '/schools' })
        setSchools(_schools)
        setLoading(false)
        dispatch({ type: 'SCHOOLS_UPDATED', schools: _schools })
      } catch (error) {
        console.error('Error while trying to fetch schools.', error)
        setLoading(false)
      }
    }

    if (!_storedSchools.length) { fetchSchools() }
  }, _storedSchools.length)

  return {
    schoolsLoading: loading,
    schools
  }
}
