import { get, omitBy, isNil } from 'lodash'
import Cookies from 'js-cookie'
import URL from 'url'
import config from 'config'

export const getTheme = ([path]) => props => get(props, `theme.${path}`)
export const callAPI = async ({ fullURL, url, method, query, body, formData }) => {
  if (formData) {
    const xhr = new XMLHttpRequest() //eslint-disable-line
    xhr.responseType = 'json'
    xhr.open(method || 'POST', `${fullURL || `${config.apiHost}${encodeURI(url)}`}`)
    xhr.setRequestHeader('access_token', Cookies.get('_optkn'))
    xhr.send(formData)

    return new Promise((resolve, reject) => {
      xhr.onload = (e) => {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject(xhr.status)
        }
      }
    })
  } else {
    const response = await fetch( // eslint-disable-line
      `${fullURL || `${config.apiHost}${encodeURI(url)}`}${URL.format({ query })}`,
      omitBy({
        method: method || (body ? 'POST' : 'GET'),
        headers: omitBy({
          'Content-Type': 'application/json; charset=utf-8',
          'access_token': Cookies.get('_optkn')
        }, isNil),
        body: (body ? JSON.stringify(body) : undefined)
      }, isNil)
    )
    const json = await response.json()
    if (response.status === 200) {
      return Promise.resolve(json)
    } else if (response.status === 401) {
      if (!(['login', 'me']).some(key => url.indexOf(key) > -1)) {
        window.location.href = '/login'
      }
      return Promise.reject({ statusCode: response.status, reason: 'Unauthorized' }) // eslint-disable-line
    } else {
      return Promise.reject({ statusCode: response.status, reason: json.reason }) // eslint-disable-line
    }
  }
}

export const emailIsValid = (email) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) // eslint-disable-line

export const identificationIdIsValid = (pid) => {
  const lastDigit = pid.slice(-1)
  const sum = Array(12).fill().reduce((sum, _, index) => sum + pid[index] * (13 - index), 0)
  return parseInt(lastDigit, 10) === ((11 - (sum % 11)) % 10)
}

export const formatComma = (number = '') =>
  (isNaN(number) ? number.replace(/,/g, '') : `${number}`).replace(/./g, (c, i, a) => i && c !== '.' && ((a.length - i) % 3 === 0) ? ',' + c : c)

export const uploadToS3 = ({ url, file, contentType, contentEncoding }) => {
  let xhr = new XMLHttpRequest() // eslint-disable-line
  xhr.open('PUT', url, true)
  if (contentType) { xhr.setRequestHeader('Content-Type', contentType) }
  xhr.send(file)
  return new Promise((resolve, reject) => {
    xhr.onload = (e) => {
      console.log(e, xhr)
      if (xhr.status === 200) {
        resolve(url.split('?')[0])
      } else {
        reject(xhr.status)
      }
    }
  })
}
