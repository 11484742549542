import React from 'react'
import { getTheme } from 'helpers'

// Components
import styled from 'styled-components'
import { Horizontal, Vertical } from 'components/Centered'
import FormButton from 'components/FormButton'
import Lottie from 'react-lottie'
import { useRouteMatch } from 'react-router-dom'
import SVG from 'react-inlinesvg'

// Animation
import * as errorAnimation from 'assets/animations/animate-error.json'

const PaymentResponse = () => {
  const match = useRouteMatch()
  const isSuccess = match.params.status === 'success'

  const _onRedirect = () => {
    window.location.href = 'https://online.oplus-school.com/main'
  }
  const _onBack = () => {
    window.location.href = '/'
  }
  return (
    <Horizontal height='calc(100vh - 60px)'>
      <Vertical>
        {!isSuccess && (
          <Lottie
            width={200}
            height={200}
            options={{
              loop: false,
              autoplay: true,
              animationData: errorAnimation.default
            }} />
        )}
        <Title>{isSuccess ? 'ชำระเงินสำเร็จ!!' : 'ชำระเงินไม่สำเร็จ' }</Title>
        <Description>
          <div>{isSuccess ? 'เริ่มต้นเรียนออนไลน์กันเลย' : 'ลองใหม่อีกครั้งหรือติดต่อ LINE @o-plus' }</div>
        </Description>
        {isSuccess && (
          <div>
            <SVG src='/svg/qr-oplus-fit-online.svg' />
            <QRText>สแกนหรือกดปุ่มเพื่อเริ่มเรียน</QRText>
            <ButtonWrapper>
              <FormButton onClick={_onRedirect}>เรียนออนไลน์</FormButton>
            </ButtonWrapper>
          </div>
        )}
        <ButtonWrapper>
          <FormButton error={!isSuccess} style={{ marginTop: 20 }} invert onClick={_onBack}>ย้อนกลับ</FormButton>
        </ButtonWrapper>
      </Vertical>
    </Horizontal>
  )
}

export default PaymentResponse

const Title = styled.div`
  margin-top: 10px;
  text-align: center;
  line-height: 31px;
  font-size: 24px;
  font-weight: 700;
  color: ${getTheme`colors.primary`};
`
const Description = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  line-height: 21px;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
  text-align: center;
`
const QRText = styled.div`
  font-size: 16px;
  margin-top: 10px;
  color: ${getTheme`colors.gray`};
`
const ButtonWrapper = styled.div`
  width: 240px;
  @media(max-width: 499px) {
    margin: 0 auto;
  }
`
