import React, { useState, useRef } from 'react'
import { getTheme, callAPI } from 'helpers'

// Components
import styled from 'styled-components'
import ContentBox from 'components/ContentBox'
import FormGroup from 'components/FormGroup'
import FormInput from 'components/FormInput'
import FormButton from 'components/FormButton'
import IconWrapper from 'components/IconWrapper'
import SVG from 'react-inlinesvg'
import Spinner from 'components/Spinner'

const ChangePassword = () => {
  const currentPasswordRef = useRef()
  const newPasswordRef = useRef()
  const confirmPasswordRef = useRef()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [focusKey, setFocusKey] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [errors, setErrors] = useState({})
  const _onSubmit = async () => {
    if (submitting) return
    let _errors = {
      currentPassword: !currentPassword && 'กรุณาระบุรหัสผ่านปัจจุบัน',
      newPassword: !newPassword && 'กรุณาระบุรหัสผ่านใหม่',
      confirmPassword: !confirmPassword && 'กรุณายืนยันรหัสผ่าน'
    }
    if (!_errors.newPassword && !_errors.confirmPassword && newPassword !== confirmPassword) {
      _errors.newPassword = 'รหัสผ่านทั้งสองไม่ตรงกัน'
      _errors.confirmPassword = 'รหัสผ่านทั้งสองไม่ตรงกัน'
    }
    setErrors(_errors)
    if (!Object.keys(_errors).some(key => _errors[key])) {
      try {
        setSubmitting(true)
        setSubmitSuccess(false)
        await callAPI({
          method: 'POST',
          url: '/students/me/change-password-securely',
          body: {
            currentPassword,
            newPassword
          }
        })
        setSubmitting(false)
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setSubmitSuccess(true)
        setTimeout(() => setSubmitSuccess(false), 1000)
      } catch (error) {
        console.error('Error while trying to submit a change password form.', error)
        setSubmitting(false)
        setSubmitSuccess(false)
        if (error.statusCode === 401) {
          setErrors({ changePassword: 'รหัสผ่านปัจจุบันไม่ถูกต้อง' })
        }
      }
    }
  }

  const _onErrorClear = (ref, key) => {
    ref.current.focus()
    setErrors(_errors => ({ ..._errors, [key]: null }))
  }

  return (
    <CustomContentBox>
      <FormHead>เปลี่ยนรหัสผ่าน</FormHead>
      <FormHeadUnderline />
      <FormBody>
        <div>
          <FormGroup error={errors.currentPassword}>
            <FormInput
              type='password'
              ref={currentPasswordRef}
              placeholder='รหัสผ่านปัจจุบัน'
              onFocus={() => setFocusKey('currentPassword')}
              onBlur={() => setFocusKey()}
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)} />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'currentPassword'}>
              <SVG src='/svg/icon-key.svg' />
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(currentPasswordRef, 'currentPassword')}>
              {errors.currentPassword}
            </div>
          </FormGroup>
          <FormGroup error={errors.newPassword}>
            <FormInput
              type='password'
              ref={newPasswordRef}
              placeholder='รหัสผ่านใหม่'
              onFocus={() => setFocusKey('newPassword')}
              onBlur={() => setFocusKey()}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)} />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'newPassword'}>
              <SVG src='/svg/icon-key.svg' />
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(newPasswordRef, 'newPassword')}>
              {errors.newPassword}
            </div>
          </FormGroup>
          <FormGroup error={errors.confirmPassword}>
            <FormInput
              type='password'
              ref={confirmPasswordRef}
              placeholder='ยืนยันรหัสผ่านใหม่'
              onFocus={() => setFocusKey('confirmPassword')}
              onBlur={() => setFocusKey()}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)} />
            <IconWrapper className='icon-wrapper' isFocused={focusKey === 'confirmPassword'}>
              <SVG src='/svg/icon-key.svg' />
            </IconWrapper>
            <div className='error-text'
              onClick={() => _onErrorClear(confirmPasswordRef, 'confirmPassword')}>
              {errors.confirmPassword}
            </div>
          </FormGroup>
          <FormButton
            style={{
              opacity: submitSuccess ? 0.7 : 1,
              cursor: submitSuccess ? 'default' : 'pointer'
            }}
            isLoading={submitting}
            onClick={_onSubmit}>
            <Spinner />
            <SVG src={(submitSuccess && !errors.changePassword) ? '/svg/icon-check.svg' : '/svg/icon-unlock.svg'} />
            <span>เปลี่ยนรหัสผ่าน{(submitSuccess && !errors.changePassword) && 'สำเร็จ'}</span>
          </FormButton>
          {errors.changePassword && <ChangePasswordError>{errors.changePassword}</ChangePasswordError>}
        </div>
      </FormBody>
    </CustomContentBox>
  )
}

export default ChangePassword

const CustomContentBox = styled(ContentBox)`
  @media(max-width: 425px) {
    margin-top: 60px;
  }
`

const FormHead = styled.div`
  color: ${getTheme`colors.primary`};
  font-weight: 700;
  font-style: italic;
  @media(min-width: 426px) {
    font-size: 36px;
  }
  @media(max-width: 425px) {
    font-size: 24px;
  }
`
const FormHeadUnderline = styled.div`
  margin: 0 auto;
  background-color: ${getTheme`colors.blue`};

  @media(min-width: 426px) {
    width: 80px;
    margin-top: 5px;
    margin-bottom: 30px;
    height: 6px;
    border-radius: 3px;
  }
  @media(max-width: 425px) {
    width: 60px;
    margin-top: 5px;
    margin-bottom: 20px;
    height: 4px;
    border-radius: 2px;
  }
`
const FormBody = styled.div`
  margin: 0 auto;
  @media(min-width: 426px) {
    width: 320px;
  }
  @media(max-width: 425px) {
    width: 240px;
  }
`
const ChangePasswordError = styled.div`
  color: #ed1c24;
  margin-top: 20px;
  &:before {
    content: '*';
  }
  @media(min-width: 426px) {
    font-size: 16px;
  }
  @media(max-width: 425px) {
    font-size: 14px;
  }
`
