import React, { useState, useEffect } from 'react'
import 'babel-polyfill'

import { getTheme, callAPI } from 'helpers'
import { useLocation, useHistory, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Containers
import Signup from 'containers/Signup'
import Login from 'containers/Login'
import Payment from 'containers/Payment'
import PaymentResponse from 'containers/PaymentResponse'

// Components
import styled from 'styled-components'
import NavBar from 'components/NavBar'
import { Horizontal, Vertical } from 'components/Centered'
import Spinner from 'components/Spinner'
import ChangePassword from 'containers/ChangePassword'
import ForgotPassword from 'containers/ForgotPassword'
import EditInfo from 'containers/EditInfo'

const unauthorizedURLs = ['/login', '/signup', '/forgot-password']
const authorizedRequiredURLs = ['/payment', '/payment-success', '/payment-failure', '/change-password', '/edit-info']

export default () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const isPaymentResponse = location.pathname === '/payment-success' || location.pathname === '/payment-failure'

  useEffect(() => {
    const verify = async () => {
      try {
        dispatch({ type: 'VERIFYING' })
        setLoading(true)
        const { result: _student } = await callAPI({ url: '/students/me' })
        setLoading(false)
        dispatch({ type: 'VERIFIED', currentStudent: _student })
        if (authorizedRequiredURLs.indexOf(location.pathname) === -1) {
          history.replace('/payment')
        }
      } catch (error) {
        console.error('Error while trying to verify a student.', error)
        setLoading(false)
        dispatch({ type: 'VERIFIED', currentStudent: null })
        if (unauthorizedURLs.indexOf(location.pathname) === -1) {
          history.replace('/signup')
        }
      }
    }

    verify()
  }, [dispatch, history, location.pathname])

  return (
    <Container>
      <NavBar />
      <ContentWrapper>
        <Vertical>
          {(loading && !isPaymentResponse) ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Switch>
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/payment' component={Payment} />
              <Route exact path='/payment-:status' component={PaymentResponse} />
              <Route exact path='/change-password' component={ChangePassword} />
              <Route exact path='/forgot-password' component={ForgotPassword} />
              <Route exact path='/edit-info' component={EditInfo} />
              <Redirect to='/signup' />
            </Switch>
          )}
        </Vertical>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${getTheme`colors.background`};

  h1 {
    margin: 0;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 24px;
    color: ${getTheme`colors.primary`};
  }
  @media(max-width: 425px) {
    min-height: 100vh;
  }
`
const ContentWrapper = styled(Horizontal)`
  margin-top: 62px;
  padding-bottom: 62px;
  &:before {
    height: calc(100vh - 62px);
  }
  @media(max-width: 425px) {
    &:before { content: unset; }
  }
`
const SpinnerWrapper = styled.div`
  margin: 30px 0px;
  color: ${getTheme`colors.secondary`};
  .spinner > svg {
    height: 60px;
  }
`
